export default {
  settings: {
    orgUsers: {
      data: [],
      currentPage: 1,
      perPage: 10,
    },
    filters: {
      orgUsers: {
        userRole: [],
        userStatus: ['active'],
        search: '',
      },
    },
  },
}
