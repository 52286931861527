import R3sFetch from '../R3sFetch'
import { handleFetchResponse } from '../ApiHelpers'

export default {
  async updateControlTesting(info) {
    const response = await R3sFetch(
      `/api/1.0/assessments/project/control/testing/update`,
      {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(info),
      }
    )
    return await handleFetchResponse(response)
  },

  async getTestingTables(projectId) {
    const response = await R3sFetch(
      `/api/1.0/assessments/testing/tables/get?id=${projectId || ''}`
    )
    return await handleFetchResponse(response)
  },

  async getTestingTablesByControl(controlId) {
    const response = await R3sFetch(
      `/api/1.0/assessments/testing/table/by/control/get?controlId=${
        controlId || ''
      }`
    )
    return await handleFetchResponse(response)
  },

  async createUpdateTestingTable(info) {
    const response = await R3sFetch(
      `/api/1.0/assessments/testing/table/create`,
      {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(info),
      }
    )
    return await handleFetchResponse(response)
  },

  async linkControlToTestingTable({
    projectId,
    tableId,
    controlId,
    deleteControl,
  }) {
    const response = await R3sFetch(
      `/api/1.0/assessments/testing/table/control/link`,
      {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ projectId, tableId, controlId, deleteControl }),
      }
    )
    return await handleFetchResponse(response)
  },

  async updateTableRows({ projectId, tableId, rows }) {
    const response = await R3sFetch(
      `/api/1.0/assessments/testing/table/rows/update`,
      {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ projectId, tableId, rows }),
      }
    )
    return await handleFetchResponse(response)
  },

  async updateTestingTableTick(info) {
    const response = await R3sFetch(
      `/api/1.0/assessments/testing/table/tick/update`,
      {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(info),
      }
    )
    return await handleFetchResponse(response)
  },

  async getTestingHistory(controlId) {
    const response = await R3sFetch(
      `/api/1.0/assessments/testing/history/get?id=${controlId || ''}`
    )
    return await handleFetchResponse(response)
  },

  async createRequestFromTestingTable(info) {
    const response = await R3sFetch(
      `/api/1.0/assessments/request/create/from/testing/table`,
      {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(info),
      }
    )
    return await handleFetchResponse(response)
  },
}
