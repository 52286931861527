<script>
  import { mapState } from 'vuex'
  import BaseFilterComponent from '../BaseFilterComponent.vue'

  export default {
    extends: BaseFilterComponent,

    data() {
      return {
        name: 'Framework',
      }
    },

    computed: {
      ...mapState({
        options: (state) => {
          return state.mycontrols.frameworks
            .filter((f) => f.is_active)
            .map((framework) => ({
              text: framework.name,
              value: framework.id,
            }))
        },
        selectedOptions(_, getters) {
          return this.options.filter((opt) =>
            getters
              .getDataByPath(this.savedFiltersStatePath + '.framework')
              .includes(opt.value)
          )
        },
      }),
    },

    mounted() {
      this.$store.dispatch('getTeamFrameworks', { reset: false })
    },
  }
</script>
