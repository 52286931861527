import AssessmentsListSummary from './assessments/AssessmentsListSummary'
import AssessmentsRequestTaskList from './assessments/AssessmentsRequestTaskList'
import ProgramChecklist from '../../programchecklist/ProgramChecklist'
import R3sContent from './R3sContent'
// import PotentialFrameworks from './assessments/PotentialFrameworks'
import OrganizationSettingsLink from './card-links/OrganizationSettingsLink'
import TrustCenterDownloadSummary from './trustcenter/TrustCenterDownloadSummary'
import TrustCenterDownloadUserSummary from './trustcenter/TrustCenterDownloadUserSummary'
import UserSettingsLink from './card-links/UserSettingsLink'

import VulnerabilityHostSummary from './vulnerability/VulnerabilityHostSummary'
import VulnerabilityOpenTrendOverTimeSummary from './vulnerability/VulnerabilityOpenTrendOverTimeSummary'
import VulnerabilityOwnerSummary from './vulnerability/VulnerabilityOwnerSummary'
import VulnerabilityScansLink from './card-links/VulnerabilityScansLink'
import VulnerabilitySeverityDonutSummary from './vulnerability/VulnerabilitySeverityDonutSummary'
import VulnerabilitySeveritySummary from './vulnerability/VulnerabilitySeveritySummary'
import VulnerabilitySLASummaryCards from './vulnerability/VulnerabilitySLASummaryCards'
import VulnerabilitySLASummaryTable from './vulnerability/VulnerabilitySLASummaryTable'

import RiskHeatMapWidget from './riskregister/RiskHeatMapWidget.vue'
import RegisterListViewWidget from './riskregister/RegisterListViewWidget.vue'
import RiskAgingWidget from './riskregister/RiskAgingWidget.vue'
import RiskByOwnerWidget from './riskregister/RiskByOwnerWidget.vue'
import RiskByXWidget from './riskregister/RiskByXWidget.vue'
import RiskHistoryWidget from './riskregister/RiskHistoryWidget.vue'
import RiskRegisterListSummary from './riskregister/RiskRegisterListSummary'
import RiskRegisterOpenItemsSummary from './riskregister/OpenItemsSummary'
import RisksMarkedCompleteCountWidget from './riskregister/RisksMarkedCompleteCountWidget.vue'
import RisksOpenedCountWidget from './riskregister/RisksOpenedCountWidget.vue'
import RiskTreatmentWidget from './riskregister/RiskTreatmentWidget.vue'
import TopRisksWidget from './riskregister/TopRisksWidget.vue'
import TotalRisksCountWidget from './riskregister/TotalRisksCountWidget.vue'

import DomainImplementationStatusWidget from './controls/DomainImplementationStatusWidget.vue'
import EvidenceStatusByUserWidget from './controls/EvidenceStatusByUserWidget.vue'
import EvidenceStatusWidget from './controls/EvidenceStatusWidget.vue'
import OperationalStatusWidget from './controls/OperationalStatusWidget.vue'
import OperationalStatusByUserWidget from './controls/OperationalStatusByUserWidget.vue'
import OperationalStatusOverTimeWidget from './controls/OperationalStatusOverTimeWidget.vue'

import TaskByOwnerWidget from './projects/TaskByOwnerWidget.vue'
import TaskBehindScheduleWidget from './projects/TaskBehindScheduleWidget.vue'
import TotalTaskCountWidget from './projects/TotalTaskCountWidget.vue'
import ProjectsWidget from './projects/ProjectsWidget.vue'
import TasksByXWidget from './projects/TasksByXWidget.vue'
import TaskHistoryWidget from './projects/TaskHistoryWidget.vue'
import TasksByCriticalityWidget from './projects/TasksByCriticalityWidget.vue'
import TasksByAgingWidget from './projects/TasksByAgingWidget.vue'
import TaskOpenedWidget from './projects/TaskOpenedWidget.vue'
import TasksMarkedCompleteWidget from './projects/TasksMarkedCompleteWidget.vue'

export default [
  // === Assessments Module ===
  {
    module: 'assessments',
    component: AssessmentsRequestTaskList,
    val: 'AssessmentsRequestTaskList',
    name: 'Evidence Requests',
    desc: `Active assessment evidence requests assigned to you.`,
  },
  {
    module: 'assessments',
    component: AssessmentsListSummary,
    val: 'AssessmentsListSummary',
    name: 'Org Assessments',
    desc: `Active assessments for your organization.`,
  },
  // {
  //   module: 'assessments',
  //   component: PotentialFrameworks,
  //   val: 'PotentialFrameworks',
  //   name: 'Potential Frameworks',
  //   desc: `Potential framework progress for your organization.`,
  // },

  // === Checklist Module ===
  {
    module: 'checklist',
    component: ProgramChecklist,
    val: 'ProgramChecklist',
    name: 'Compliance Program Checklist',
    desc: `Checklist to track your compliance program completion journey.`,
  },

  // === Controls Module ===
  {
    module: 'my_controls',
    component: OperationalStatusWidget,
    val: 'OperationalStatus',
    name: 'Control Operational Status',
    desc: 'Overview of the operational status of controls across your frameworks.',
  },
  {
    module: 'my_controls',
    component: EvidenceStatusWidget,
    val: 'EvidenceStatus',
    name: 'Control Evidence Status',
    desc: 'Overview of the evidence status of controls across your frameworks.',
  },
  {
    module: 'my_controls',
    component: OperationalStatusByUserWidget,
    val: 'OperationalStatusByUser',
    name: 'Control Operational Status By User',
    desc: 'By-User Overview of the opertaional status of controls across your frameworks.',
  },
  {
    module: 'my_controls',
    component: EvidenceStatusByUserWidget,
    val: 'EvidenceStatusByUser',
    name: 'Control Evidence Status By User',
    desc: 'By-User Overview of the evidence status of controls across your frameworks.',
  },
  {
    module: 'my_controls',
    component: OperationalStatusOverTimeWidget,
    val: 'OperationalStatusOverTime',
    name: 'Control Operational Status Over Time',
    desc: 'A line graph showing the operational status of controls over time.',
  },
  {
    module: 'my_controls',
    component: DomainImplementationStatusWidget,
    val: 'DomainImplementationStatus',
    name: 'Domain Implementation Status',
    desc: 'Overview of the implementation status of controls across your frameworks by domain.',
  },

  // === Risk Register Module ===
  {
    module: 'risk_register',
    component: RiskRegisterListSummary,
    val: 'RiskRegisterListSummary',
    name: 'Org Risk Registers',
    desc: `Active risk registers for your organization.`,
  },
  {
    module: 'risk_register',
    component: RiskRegisterOpenItemsSummary,
    val: 'RiskRegisterOpenItemsSummary',
    name: 'Org Risks',
    desc: `A summary of all outstanding/open risk register items for your organization.`,
  },
  {
    module: 'risk_register',
    component: RiskByOwnerWidget,
    val: 'RiskByOwner',
    name: 'Risk By Owner',
    desc: `Overview of all risks by owner.`,
  },
  {
    module: 'risk_register',
    component: TopRisksWidget,
    val: 'TopRisks',
    name: 'Top Risks',
    desc: `Overview of top risks.`,
  },
  {
    module: 'risk_register',
    component: RegisterListViewWidget,
    val: 'RegisterListView',
    name: 'Risk Registers',
    desc: `Overview of risk registers.`,
  },
  {
    module: 'risk_register',
    component: RiskTreatmentWidget,
    val: 'RiskTreatment',
    name: 'Risks by Treatment Decision',
    desc: `Overview of risks grouped by their treatment decisions.`,
  },
  {
    module: 'risk_register',
    component: RiskAgingWidget,
    val: 'RiskAging',
    name: 'Risk Aging Overview',
    desc: `Overview of risk age distribution.`,
  },
  {
    module: 'risk_register',
    component: TotalRisksCountWidget,
    val: 'TotalRisksCount',
    name: 'Total Risks Count',
    desc: `Total count of risks.`,
  },
  {
    module: 'risk_register',
    component: RisksOpenedCountWidget,
    val: 'RisksOpenedCount',
    name: 'Risks Opened Count',
    desc: `Total count of risks opened.`,
  },
  {
    module: 'risk_register',
    component: RisksMarkedCompleteCountWidget,
    val: 'RisksMarkedCompleteCount',
    name: 'Risks Marked Complete Count',
    desc: `Total count of risks marked complete.`,
  },
  {
    module: 'risk_register',
    component: RiskByXWidget,
    val: 'RiskByX',
    name: 'Risks By X',
    desc: `Overview of risks by pivot.`,
  },
  {
    module: 'risk_register',
    component: RiskHistoryWidget,
    val: 'RiskHistory',
    name: 'Risk History',
    desc: `Overview of risk history.`,
  },
  {
    module: 'risk_register',
    component: RiskHeatMapWidget,
    val: 'HeatMap',
    name: 'Risk Heat Map',
    desc: `Heat Map of risk impact vs likelihood.`,
  },

  // === Settings Module ===
  {
    module: 'settings',
    component: R3sContent,
    val: 'R3sContent',
    name: 'Fun Facts',
    desc: `Fun facts brought to you by risk3sixty!`,
  },
  {
    module: 'settings',
    component: OrganizationSettingsLink,
    val: 'OrganizationSettingsLink',
    name: 'Org Settings',
    desc: `A shortcut link to manage your top-level team settings`,
  },
  {
    module: 'settings',
    component: UserSettingsLink,
    val: 'UserSettingsLink',
    name: 'User Settings',
    desc: `A shortcut link to manage your personal account information.`,
  },

  // === Template Center Module ===
  {
    module: 'template_center',
    component: TrustCenterDownloadSummary,
    val: 'TrustCenterDownloadSummary',
    name: 'Template Center Downloads',
    desc: `Information about the most downloaded files in the template center.`,
  },
  {
    module: 'template_center',
    component: TrustCenterDownloadUserSummary,
    val: 'TrustCenterDownloadUserSummary',
    name: 'Template Center Downloads Per User',
    desc: `Information about the most downloaded files in the template center by selected user.`,
  },

  // === Vulnerability Module ===
  {
    module: 'vulnerability',
    component: VulnerabilityHostSummary,
    val: 'VulnerabilityHostSummary',
    name: 'Host Vulnerabilities',
    desc: `A summary of vulnerabilities specific to the affected hosts`,
  },
  {
    module: 'vulnerability',
    component: VulnerabilityOpenTrendOverTimeSummary,
    val: 'VulnerabilityOpenTrendOverTimeSummary',
    name: 'Vulnerabilities Opened Over Time',
    desc: `Line graph showing how many open items there are over time.`,
  },
  {
    module: 'vulnerability',
    component: VulnerabilityOwnerSummary,
    val: 'VulnerabilityOwnerSummary',
    name: 'Vulnerability Scans Per Owner',
    desc: `A summary of all of your vulnerability scans around the item's owners`,
  },
  {
    module: 'vulnerability',
    component: VulnerabilityScansLink,
    val: 'VulnerabilityScansLink',
    name: 'Vulnerability Scans',
    desc: 'A shortcut link to your vulnerability scans',
  },
  {
    module: 'vulnerability',
    component: VulnerabilitySeverityDonutSummary,
    val: 'VulnerabilitySeverityDonutSummary',
    name: 'Vulnerabilities By Severity Chart',
    desc: `A doughnut chart of severity of items across all of you vulnerabilities`,
  },
  {
    module: 'vulnerability',
    component: VulnerabilitySeveritySummary,
    val: 'VulnerabilitySeveritySummary',
    name: 'Vulnerability Scans By Severity',
    desc: `A summary of all of your vulnerability scans by severity`,
  },
  {
    module: 'vulnerability',
    component: VulnerabilitySLASummaryCards,
    val: 'VulnerabilitySLASummaryCards',
    name: 'Vulnerability SLAs By Severity',
    desc: `A summary of how long it takes issues by severity to be completed.`,
  },
  {
    module: 'vulnerability',
    component: VulnerabilitySLASummaryTable,
    val: 'VulnerabilitySLASummaryTable',
    name: 'Vulnerability SLAs By Severity Table',
    desc: `SLA information for how long it takes to close out issues by severity.`,
  },
  // === Project Management Module ===
  {
    module: 'projects',
    component: TaskByOwnerWidget,
    val: 'TaskByOwnerWidget',
    name: 'Tasks By Owner',
    desc: `Overview of all tasks by owner.`,
  },
  {
    module: 'projects',
    component: TaskBehindScheduleWidget,
    val: 'TaskBehindScheduleWidget',
    name: 'Tasks Behind Schedule',
    desc: `Overview of tasks behind schedule.`,
  },
  {
    module: 'projects',
    component: ProjectsWidget,
    val: 'ProjectsWidget',
    name: 'Projects',
    desc: `Overview of projects.`,
  },
  {
    module: 'projects',
    component: TasksByXWidget,
    val: 'TasksByXWidget',
    name: 'Tasks By X',
    desc: `Overview of tasks by pivot.`,
  },
  {
    module: 'projects',
    component: TaskHistoryWidget,
    val: 'TaskHistoryWidget',
    name: 'Open Tasks By Criticality',
    desc: `Overview of open tasks by Criticality.`,
  },
  {
    module: 'projects',
    component: TasksByCriticalityWidget,
    val: 'TasksByCriticalityWidget',
    name: 'Tasks By Criticality',
    desc: `Overview of tasks by criticality.`,
  },
  {
    module: 'projects',
    component: TasksByAgingWidget,
    val: 'TasksByAgingWidget',
    name: 'Tasks By Aging',
    desc: `Overview of task aging distribution.`,
  },
  {
    module: 'projects',
    component: TotalTaskCountWidget,
    val: 'TotalTaskCountWidget',
    name: 'Total Task Count',
    desc: `Total count of tasks.`,
  },
  {
    module: 'projects',
    component: TaskOpenedWidget,
    val: 'TaskOpenedWidget',
    name: 'Tasks Opened',
    desc: `Overview of tasks opened.`,
  },
  {
    module: 'projects',
    component: TasksMarkedCompleteWidget,
    val: 'TasksMarkedCompleteWidget',
    name: 'Tasks Marked Complete',
    desc: `Overview of tasks marked complete.`,
  },
]
