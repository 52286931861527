const inherentRiskText =
  'The risk to an entity in the absence of any direct or focused actions by management to alter its severity.'
const residualRiskText =
  'Portion of risk remaining after security measures have been applied.  Calculated as Impact x Likelihood.'
const acceptableRiskTextShort =
  'The amount of risk that an entity prefers to assume in the pursuit of its strategy and business objectives'
const acceptableRiskText = `${acceptableRiskTextShort}, knowing that management will implement, or has implemented, direct or focused actions to alter the severity of the risk.`

export const glossary = {
  riskRegister: {
    risks: {
      INHERENT_RISK: inherentRiskText,
      INHERENT_RISK_WITH_LINK: `${inherentRiskText} </br><a class='link-text' target='_blank' rel='noopener noreferrer' href='https://csrc.nist.gov/glossary/term/inherent_risk'>Inherent Risk - Glossary | CSRC</a>`,
      RESIDUAL_RISK: residualRiskText,
      RESIDUAL_RISK_WITH_LINK: `${residualRiskText} </br><a class='link-text' target='_blank' rel='noopener noreferrer' href='https://csrc.nist.gov/glossary/term/residual_risk'>Residual Risk - Glossary | CSRC</a>`,
      ACCEPTABLE_RISK: `${acceptableRiskTextShort}. <br><br> <b>You can set the default acceptable risk in Policies.</b>`,
      ACCEPTABLE_RISK_WITH_LINK: `${acceptableRiskText} </br><a class='link-text' target='_blank' rel='noopener noreferrer' href='https://csrc.nist.gov/glossary/term/target_residual_risk'>Target Residual Risk - Glossary | CSRC</a>`,
    },
    treatmentDecisions: {
      ACCEPT:
        'Risk acceptance is the appropriate risk response when the identified risk is within the organizational risk tolerance. Organizations can accept risk deemed to be low, moderate, or high depending on particular situations or conditions.',
      TRANSFER:
        'Risk transfer shifts the entire risk responsibility or liability from one organization to another organization (e.g., using insurance to transfer risk from particular organizations to insurance companies).',
      MITIGATE:
        'Risk mitigation, or risk reduction, is the appropriate risk response for that portion of risk that cannot be accepted, avoided, shared, or transferred.',
      SHARE:
        'Risk sharing shifts a portion of risk responsibility or liability to other organizations (usually organizations that are more qualified to address the risk).',
      AVOID:
        'Risk avoidance may be the appropriate risk response when the identified risk exceeds the organizational risk tolerance.  Organizations may conduct certain types of activities or employ certain types of information technologies that result in risk that is unacceptable. In such situations, risk avoidance involves taking specific actions to eliminate the activities or technologies that are the basis for the risk or to revise or reposition these activities or technologies in the organizational mission/business processes to avoid the potential for unacceptable risk',
    },
  },
}
