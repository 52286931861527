import ApiUsers from '../../factories/ApiUsers'
export default {
  async setFilterAndGetOrgUsers({ dispatch, commit }, { key, val }) {
    commit('SET_ORG_USERS_FILTER', { key, val })
    await dispatch('getOrgUsers', { currentPage: 1 })
  },

  async clearFiltersAndGetOrgUsers({ dispatch, commit }) {
    commit('CLEAR_ORG_USERS_FILTER')
    await dispatch('getOrgUsers', { currentPage: 1 })
  },

  async getOrgUsers({ commit, state }, { filters, currentPage, perPage } = {}) {
    filters = filters || state.settings.filters.orgUsers || {}
    currentPage = currentPage || state.settings.orgUsers.currentPage || 1
    perPage = perPage || state.settings.orgUsers.perPage || 10e9

    const teamUsers = await ApiUsers.getUsersInTeam(
      filters,
      currentPage,
      perPage
    )

    commit('SET_ORG_USERS', teamUsers)
  },
}
