<script>
  import { mapState } from 'vuex'
  import BaseFilterComponent from '../BaseFilterComponent.vue'

  export default {
    extends: BaseFilterComponent,

    data() {
      return {
        name: 'User Role',
      }
    },

    computed: {
      ...mapState({
        options: (state) =>
          state.userRoles.map((r) => ({ value: r.val, text: r.text })),
        selectedOptions(_, getters) {
          return this.options.filter((opt) =>
            getters
              .getDataByPath(this.savedFiltersStatePath + '.userRole')
              .includes(opt.value)
          )
        },
      }),
    },
  }
</script>
