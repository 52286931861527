<script>
  import { mapState } from 'vuex'
  import BaseFilterComponent from '../BaseFilterComponent.vue'
  import StringHelpers from '../../../../../factories/StringHelpers'

  export default {
    extends: BaseFilterComponent,

    data() {
      return {
        name: 'Risk Score',
      }
    },

    computed: {
      ...mapState({
        options: (state) =>
          state.vendormanagement.riskScores.map((s) => ({
            text: StringHelpers.titleCase(s.header, true),
            value: s.header,
            variant: s.color,
          })),
        selectedOptions(_, getters) {
          return this.options.filter((opt) =>
            getters
              .getDataByPath(this.savedFiltersStatePath + '.riskScore')
              .includes(opt.value)
          )
        },
      }),
    },

    methods: {
      titleCase: StringHelpers.titleCase,
    },

    mounted() {},
  }
</script>
