<script>
  import { mapState } from 'vuex'
  import BaseFilterComponent from '../BaseFilterComponent.vue'
  import Strings from '../../../../../factories/StringHelpers'

  export default {
    extends: BaseFilterComponent,

    data() {
      return {
        name: 'Controls',
      }
    },

    computed: {
      ...mapState({
        options(state) {
          const sortedControlGroups = [
            ...state.mycontrols.controlGroups.data,
          ].sort((a, b) => a.name.localeCompare(b.name))
          return [{ name: 'No Mapped Controls', id: null }]
            .concat(sortedControlGroups || [])
            .map((cg) => ({
              text: Strings.truncateString(cg.name, 30),
              value: cg.id,
            }))
        },

        selectedOptions(_, getters) {
          return this.options.filter((opt) =>
            getters
              .getDataByPath(this.savedFiltersStatePath + '.controlGroup')
              .includes(opt.value)
          )
        },
      }),
    },

    mounted() {
      this.$store.dispatch('getTeamControlGroups', false)
    },
  }
</script>
