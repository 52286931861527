<script>
  import { mapState } from 'vuex'
  import BaseFilterComponent from '../BaseFilterComponent.vue'

  export default {
    extends: BaseFilterComponent,

    data() {
      return {
        name: 'Date',
      }
    },

    computed: {
      options() {
        return [
          {
            text: 'Today',
            value: 'created_today',
          },
          {
            text: 'Yesterday',
            value: 'created_yesterday',
          },
          {
            text: 'This Week',
            value: 'created_last_seven_days',
          },
          {
            text: 'This Month',
            value: 'created_last_thirty_days',
          },
          {
            text: 'This 3 Months',
            value: 'created_last_ninety_days',
          },
          {
            text: 'This Year',
            value: 'created_last_year',
          },
          {
            text: 'All Time',
            value: 'all_time',
          },
        ]
      },
      ...mapState({
        selectedOptions(_, getters) {
          return this.options.filter((opt) =>
            getters
              .getDataByPath(this.savedFiltersStatePath + '.createdDate')
              .includes(opt.value)
          )
        },
      }),
    },
  }
</script>
