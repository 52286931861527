import { createRouter, createWebHistory } from 'vue-router'

const AdminContainer = () => import('@/components/admin/AdminContainer')
const AssessmentsContainer = () =>
  import('@/components/assessments/containers/AssessmentsContainer')
const AutomationsContainer = () =>
  import('@/components/automations/AutomationsContainer')
const AuthBanner = () => import('@/components/auth/AuthBanner')
const AuthVerification = () => import('@/components/auth/AuthVerification')
const InventoriesContainer = () =>
  import('@/components/inventories/InventoriesContainer')
const ComplianceCalendarContainer = () =>
  import('@/components/compliance/containers/ComplianceCalendarContainer')
const DashboardContainer = () =>
  import('@/components/dashboard/DashboardContainer')
const Eula = () => import('@/components/auth/Eula')
const ConfirmResetPassword = () =>
  import('@/components/auth/ConfirmResetPassword')
const ProgramChecklist = () =>
  import('@/components/programchecklist/ProgramChecklist')
const HostGroupList = () => import('@/components/hosts/HostGroupList')
const HostGroupMgmt = () => import('@/components/hosts/HostGroupMgmt')
const IndividualHostDetail = () =>
  import('@/components/hosts/IndividualHostDetail')
const Risk3sixtyRedirecting = () => import('@/components/Risk3sixtyRedirecting')
const Login = () => import('@/components/auth/Login')
const Mfa = () => import('@/components/auth/Mfa')
const MyControls = () => import('@/components/mycontrols/MyControlsContainer')
const PciContainer = () => import('@/components/pci/PciContainer')
const PlatformIntelligenceContainer = () =>
  import('@/components/admin/PlatformIntelligence')
const ProjectManagementContainer = () =>
  import('@/components/projects/ProjectManagementContainer')
const RemoteNodeCommandDetail = () =>
  import('@/components/vulnerability/RemoteAccess/CommandDetail')
const RemoteNodeDetail = () =>
  import('@/components/vulnerability/RemoteAccess/NodeDetail')
const ReportsContainer = () => import('@/components/reporting/ReportsContainer')
const RiskRegisterContainer = () =>
  import('@/components/riskregister/RiskRegisterContainer')
const SecureMessagesContainer = () =>
  import('@/components/core/SecureMessagesContainer')
const SelfAssessmentsContainer = () =>
  import('@/components/selfassessments/SelfAssessmentsContainer')
const SettingsContainer = () =>
  import('@/components/settings/SettingsContainer')
const StyleGuide = () => import('@/components/styleguide/StyleGuide')
const SubscriptionUpdate = () => import('@/components/auth/SubscriptionUpdate')
const TemplateCenterHome = () =>
  import('@/components/templatecenter/TemplateCenterHome')
const UserPublicProfileContainer = () =>
  import('@/components/core/UserPublicProfileContainer')
const VendorManagementContainer = () =>
  import('@/components/vendormanagement/VendorManagementContainer')
const VulnerabilityHome = () =>
  import('@/components/vulnerability/HomeContainer')
const VulnerabilityScanItem = () =>
  import('@/components/vulnerability/ScanItem/index')
const VulnerabilityReportSLA = () =>
  import('@/components/vulnerability/reports/SLA')
const WikiContainer = () => import('@/components/wiki/WikiContainer')

export const router = createRouter({
  history: createWebHistory(),
  routes: getRoutes().map((r) => ({
    path: r.path,
    component: r.component,
    props: r.props,
    meta: r.meta,
  })),
})

export function getRoutes() {
  return [
    {
      path: '/redirecting',
      component: Risk3sixtyRedirecting,
      breadcrumbs: [{ text: 'Home', link: '/' }],
      title: 'Home',
      subTitle: 'fullCircle',
    },
    {
      path: '/admin',
      component: AdminContainer,
      breadcrumbs: [{ text: 'Home', link: '/' }],
      title: 'Home',
      subTitle: 'fullCircle',
    },
    {
      path: '/admin/:page(.*)*',
      component: AdminContainer,
      props: (route) => ({
        page: route.params.page.join('/'),
      }),
      breadcrumbs: [{ text: 'Home', link: '/' }],
      title: (params) => params.pageTitle,
      subTitle: 'Administration',
    },
    {
      path: '/assessments',
      component: AssessmentsContainer,
      props: { module: 'assessments' },
      breadcrumbs: [{ text: 'Home', link: '/' }],
      title: (params) => params.pageTitle,
      subTitle: 'Assessments',
    },
    {
      path: '/assessments/:tab',
      component: AssessmentsContainer,
      props: (route) => ({ module: 'assessments', tab: route.params.tab }),
      breadcrumbs: [
        { text: 'Home', link: '/' },
        { text: 'Assessments', link: '/assessments' },
      ],
      title: (params) => params.pageTitle,
      subTitle: 'Assessments',
    },
    {
      path: '/assessments/:tab/:assessmentId',
      component: AssessmentsContainer,
      props: (route) => ({
        module: 'assessments',
        tab: route.params.tab,
        assessmentId: route.params.assessmentId,
      }),
      breadcrumbs: [
        { text: 'Home', link: '/' },
        { text: 'Assessments', link: '/assessments' },
      ],
      title: (params) => params.pageTitle,
      subTitle: 'Assessments',
    },
    {
      path: '/assessments/:tab/:assessmentId/:requestId',
      component: AssessmentsContainer,
      props: (route) => ({
        module: 'assessments',
        tab: route.params.tab,
        assessmentId: route.params.assessmentId,
        requestId: route.params.requestId,
      }),
      breadcrumbs: [
        { text: 'Home', link: '/' },
        { text: 'Assessments', link: '/assessments' },
        {
          text: (params) => params.pageTitle,
          link: (params) => `/assessments/${params.tab}/${params.id}`,
        },
      ],
      title: (params) => params.pageTitle,
      subTitle: 'Assessments',
    },
    {
      path: '/automations',
      component: AutomationsContainer,
      breadcrumbs: [
        { text: 'Home', link: '/' },
        { text: 'Automations', link: '/automations' },
      ],
      title: (params) => params.pageTitle,
      subTitle: 'Automations',
    },
    {
      path: '/automations/apps/:appId',
      component: AutomationsContainer,
      props: true,
      breadcrumbs: [
        { text: 'Home', link: '/' },
        { text: 'Automations', link: '/automations' },
      ],
      title: (params) => params.pageTitle,
      subTitle: 'Apps',
    },
    {
      path: '/automations/apps/:appId/:instanceId',
      component: AutomationsContainer,
      props: true,
      breadcrumbs: [
        { text: 'Home', link: '/' },
        { text: 'Automations', link: '/automations' },
      ],
      title: (params) => params.pageTitle,
      subTitle: 'Apps',
    },
    {
      path: '/automations/apps/:appId/:instanceId/:executionId',
      component: AutomationsContainer,
      props: true,
      breadcrumbs: [
        { text: 'Home', link: '/' },
        { text: 'Automations', link: '/automations' },
      ],
      title: (params) => params.pageTitle,
      subTitle: 'Apps',
    },
    {
      path: '/automations/apps/:appId/:instanceId/:executionId/:serviceKey',
      component: AutomationsContainer,
      props: true,
      breadcrumbs: [
        { text: 'Home', link: '/' },
        { text: 'Automations', link: '/automations' },
      ],
      title: (params) => params.pageTitle,
      subTitle: 'Apps',
    },
    {
      path: '/automations/apps/:appId/:instanceId/:executionId/:serviceKey/:findingKey',
      component: AutomationsContainer,
      props: true,
      breadcrumbs: [
        { text: 'Home', link: '/' },
        { text: 'Automations', link: '/automations' },
      ],
      title: (params) => params.pageTitle,
      subTitle: 'Apps',
    },
    {
      path: '/automations/:extensionId',
      component: AutomationsContainer,
      props: true,
      breadcrumbs: [
        { text: 'Home', link: '/' },
        { text: 'Automations', link: '/automations' },
      ],
      title: (params) => params.pageTitle,
      subTitle: 'Automations',
    },
    {
      path: '/pentesting',
      component: AssessmentsContainer,
      props: {
        module: 'pentesting',
      },
      breadcrumbs: [{ text: 'Home', link: '/' }],
      title: 'Penetration Testing Home',
      subTitle: 'Penetration Testing',
    },
    {
      path: '/pentesting/vulnerability/:scanId/item/:itemId',
      component: VulnerabilityScanItem,
      props: true,
      breadcrumbs: [
        { text: 'Home', link: '/' },
        { text: 'Penetration Testing', link: '/Penetration Testing' },
        {
          text: (params) => params.pageTitle,
          link: (params) => `/pentesting/${params.tab}/${params.id}`,
        },
      ],
      title: (params) => params.pageTitle,
      subTitle: 'Penetration Testing',
    },
    {
      path: '/pentesting/:tab',
      component: AssessmentsContainer,
      props: (route) => ({
        module: 'pentesting',
        tab: route.params.tab,
      }),
      breadcrumbs: [
        { text: 'Home', link: '/' },
        { text: 'Penetration Testing', link: '/pentesting' },
      ],
      title: (params) => params.pageTitle,
      subTitle: 'Penetration Testing',
    },
    {
      path: '/pentesting/:tab/:assessmentId',
      component: AssessmentsContainer,
      props: (route) => ({
        module: 'pentesting',
        tab: route.params.tab,
        assessmentId: route.params.assessmentId,
      }),
      breadcrumbs: [
        { text: 'Home', link: '/' },
        { text: 'Penetration Testing', link: '/Penetration Testing' },
      ],
      title: (params) => params.pageTitle,
      subTitle: 'Penetration Testing',
    },
    {
      path: '/pentesting/:tab/:assessmentId/:requestId',
      component: AssessmentsContainer,
      props: (route) => ({
        module: 'pentesting',
        tab: route.params.tab,
        assessmentId: route.params.assessmentId,
        requestId: route.params.requestId,
      }),
      breadcrumbs: [
        { text: 'Home', link: '/' },
        { text: 'Penetration Testing', link: '/Penetration Testing' },
        {
          text: (params) => params.pageTitle,
          link: (params) => `/pentesting/${params.tab}/${params.id}`,
        },
      ],
      title: (params) => params.pageTitle,
      subTitle: 'Penetration Testing',
    },
    {
      path: '/pentesting/:tab/:assessmentId/:requestId/:subId',
      component: AssessmentsContainer,
      props: (route) => ({
        module: 'pentesting',
        tab: route.params.tab,
        assessmentId: route.params.assessmentId,
        requestId: route.params.requestId,
        subId: route.params.subId,
      }),
      breadcrumbs: [
        { text: 'Home', link: '/' },
        { text: 'Penetration Testing', link: '/Penetration Testing' },
        {
          text: (params) => params.pageTitle,
          link: (params) => `/pentesting/${params.tab}/${params.id}`,
        },
      ],
      title: (params) => params.pageTitle,
      subTitle: 'Penetration Testing',
    },
    {
      path: '/assets/groups',
      component: HostGroupList,
      props: true,
      breadcrumbs: [
        { text: 'Home', link: '/' },
        { text: 'Asset Management', link: '/vulnerability/list/assets' },
      ],
      title: 'Assets',
      subTitle: 'Asset Management',
    },
    {
      path: '/assets/host/:hostId',
      component: IndividualHostDetail,
      props: true,
      breadcrumbs: [
        { text: 'Home', link: '/' },
        { text: 'Asset Management', link: '/vulnerability/list/assets' },
      ],
      title: (params) => params.pageTitle,
      subTitle: 'Asset Management',
    },
    {
      path: '/assets/groups/:groupId',
      component: HostGroupMgmt,
      props: true,
      breadcrumbs: [
        { text: 'Home', link: '/' },
        { text: 'Asset Management', link: '/vulnerability/list/assets' },
        { text: 'Asset Groups', link: '/assets/groups' },
      ],
      title: 'Asset Groups',
      subTitle: 'Asset Management',
    },
    {
      path: '/autherror/:error',
      component: AuthBanner,
      props: true,
      title: 'Authentication Error',
      subTitle: 'fullCircle',
      meta: { requiresAuth: false },
    },
    {
      path: '/success/:success',
      component: AuthBanner,
      props: true,
      title: 'Authentication Error',
      subTitle: 'fullCircle',
      meta: { requiresAuth: false },
    },
    {
      path: '/compliance/:tab',
      component: ComplianceCalendarContainer,
      props: true,
      breadcrumbs: [{ text: 'Home', link: '/' }],
      title: 'Compliance Calendar',
      subTitle: 'Compliance',
    },
    {
      path: '/compliance/:tab/:eventId',
      component: ComplianceCalendarContainer,
      props: true,
      breadcrumbs: [{ text: 'Home', link: '/' }],
      title: 'Compliance Calendar',
      subTitle: 'Compliance',
    },
    {
      path: '/checklist',
      component: ProgramChecklist,
      breadcrumbs: [{ text: 'Home', link: '/' }],
      title: (params) => params.pageTitle || 'My Program Checklist',
      subTitle: 'My Program Checklist',
    },
    {
      path: '/projects',
      component: ProjectManagementContainer,
      breadcrumbs: [{ text: 'Home', link: '/' }],
      title: 'Dashboard',
      subTitle: 'Project Management',
      props: { mainTab: 'dashboard' },
    },
    {
      path: '/projects/all',
      component: ProjectManagementContainer,
      breadcrumbs: [
        { text: 'Home', link: '/' },
        { text: 'Dashboard', link: '/projects' },
      ],
      title: 'All Projects',
      subTitle: 'Project Management',
      props: { mainTab: 'listView' },
    },
    {
      path: '/projects/:projectId',
      component: ProjectManagementContainer,
      props: true,
      breadcrumbs: [{ text: 'Home', link: '/' }],
      title: (params) => params.pageTitle || 'Project Management',
      subTitle: 'Project Management',
    },
    {
      path: '/projects/reports',
      component: ProjectManagementContainer,
      props: { mainTab: 'reports' },
      breadcrumbs: [{ text: 'Home', link: '/' }],
      title: 'Reports',
      subTitle: 'Project Management',
    },
    {
      path: '/projects/:projectId/:tab',
      component: ProjectManagementContainer,
      props: true,
      breadcrumbs: [
        { text: 'Home', link: '/' },
        { text: 'Projects', link: '/projects/all' },
      ],
      title: (params) => params.pageTitle || 'Project Management',
      subTitle: 'Project Management',
    },
    {
      path: '/projects/:projectId/:tab/:taskId',
      component: ProjectManagementContainer,
      props: true,
      breadcrumbs: [
        { text: 'Home', link: '/' },
        { text: 'Projects', link: '/projects/all' },
      ],
      title: (params) => params.pageTitle || 'Project Management',
      subTitle: 'Project Management',
    },
    {
      path: '/projects/:projectId/:tab/:taskId/:subTab',
      component: ProjectManagementContainer,
      props: true,
      breadcrumbs: [
        { text: 'Home', link: '/' },
        { text: 'Projects', link: '/projects/all' },
      ],
      title: (params) => params.pageTitle || 'Project Management',
      subTitle: 'Project Management',
    },
    {
      path: '/platform/intel',
      component: PlatformIntelligenceContainer,
      props: true,
      breadcrumbs: [{ text: 'Home', link: '/' }],
      title: (params) => params.pageTitle || 'Platform Intelligence',
      subtitle: 'Platform Intelligence',
    },
    {
      path: '/platform/intel/:tab',
      component: PlatformIntelligenceContainer,
      props: true,
      breadcrumbs: [{ text: 'Home', link: '/' }],
      title: (params) => params.pageTitle || 'Platform Intelligence',
      subtitle: 'Platform Intelligence',
    },
    {
      path: '/platform/intel/:tab/:subTab',
      component: PlatformIntelligenceContainer,
      props: true,
      breadcrumbs: [{ text: 'Home', link: '/' }],
      title: (params) => params.pageTitle || 'Platform Intelligence',
      subtitle: 'Platform Intelligence',
    },
    {
      path: '/platform/intel/:tab/:subTab/:subTabId',
      component: PlatformIntelligenceContainer,
      props: true,
      breadcrumbs: [{ text: 'Home', link: '/' }],
      title: (params) => params.pageTitle || 'Platform Intelligence',
      subtitle: 'Platform Intelligence',
    },
    {
      path: '/platform/intel/:tab/:subTab/:subTabId/:subSubTabId',
      component: PlatformIntelligenceContainer,
      props: true,
      breadcrumbs: [{ text: 'Home', link: '/' }],
      title: (params) => params.pageTitle || 'Platform Intelligence',
      subtitle: 'Platform Intelligence',
    },
    {
      path: '/platform/intel/:tab/:subTab/:subTabId/:subSubTabId/:subSubTab',
      component: PlatformIntelligenceContainer,
      props: true,
      breadcrumbs: [{ text: 'Home', link: '/' }],
      title: (params) => params.pageTitle || 'Platform Intelligence',
      subtitle: 'Platform Intelligence',
    },
    {
      path: '/risk/register',
      component: RiskRegisterContainer,
      breadcrumbs: [{ text: 'Home', link: '/' }],
      title: 'Dashboard',
      subTitle: 'Risk Management',
    },
    {
      path: '/risk/register/registers',
      component: RiskRegisterContainer,
      props: { mainTab: 'listView' },
      breadcrumbs: [
        { text: 'Home', link: '/' },
        { text: 'Dashboard', link: '/risk/register' },
      ],
      title: 'All Registers',
      subTitle: 'Risk Management',
    },
    {
      path: '/risk/register/reports',
      component: RiskRegisterContainer,
      props: { mainTab: 'reports' },
      breadcrumbs: [
        { text: 'Home', link: '/' },
        { text: 'Dashboard', link: '/risk/register' },
      ],
      title: 'Reports',
      subTitle: 'Risk Management',
    },
    {
      path: '/risk/register/:registerId',
      component: RiskRegisterContainer,
      props: true,
      breadcrumbs: [{ text: 'Home', link: '/' }],
      title: (params) => params.pageTitle || 'Risk Register',
      subTitle: 'Risk Management',
    },
    {
      path: '/risk/register/:registerId/:tab',
      component: RiskRegisterContainer,
      props: true,
      breadcrumbs: [
        { text: 'Home', link: '/' },
        { text: 'Dashboard', link: '/risk/register' },
      ],
      title: (params) => params.pageTitle || 'Risk Register',
      subTitle: 'Risk Management',
    },
    {
      path: '/risk/register/:registerId/:tab/:itemId',
      component: RiskRegisterContainer,
      props: true,
      breadcrumbs: [
        { text: 'Home', link: '/' },
        { text: 'Risk Registers', link: '/risk/register' },
      ],
      title: (params) => params.pageTitle || 'Risk Register',
      subTitle: 'Risk Management',
    },
    {
      path: '/risk/register/:registerId/:tab/:itemId/:subTab',
      component: RiskRegisterContainer,
      props: true,
      breadcrumbs: [
        { text: 'Home', link: '/' },
        { text: 'Risk Registers', link: '/risk/register' },
        { text: 'Risk Register', link: '/risk/register/:registerId' },
      ],
      title: (params) => params.pageTitle || 'Risk Register',
      subTitle: 'Risk Management',
    },
    {
      path: '/inventories',
      component: InventoriesContainer,
      breadcrumbs: [{ text: 'Home', link: '/' }],
      title: (params) => params.pageTitle || 'Inventories',
      subTitle: 'Inventories',
    },
    {
      path: '/inventories/:biaId',
      component: InventoriesContainer,
      props: true,
      breadcrumbs: [{ text: 'Home', link: '/' }],
      title: (params) => params.pageTitle || 'Inventories',
      subTitle: 'Inventories',
    },
    {
      path: '/inventories/:biaId/:tab',
      component: InventoriesContainer,
      props: true,
      breadcrumbs: [{ text: 'Home', link: '/' }],
      title: (params) => params.pageTitle || 'Inventories',
      subTitle: 'Inventories',
    },
    {
      path: '/vendor/management',
      component: VendorManagementContainer,
      breadcrumbs: [{ text: 'Home', link: '/' }],
      title: (params) => params.pageTitle || 'Vendor Management',
      subTitle: 'Vendor Management',
    },
    {
      path: '/vendor/management/:mainTab',
      component: VendorManagementContainer,
      props: true,
      breadcrumbs: [{ text: 'Home', link: '/' }],
      title: (params) => params.pageTitle || 'Vendor Management',
      subTitle: 'Vendor Management',
    },
    {
      path: '/vendor/management/:mainTab/:entityId',
      component: VendorManagementContainer,
      props: true,
      breadcrumbs: [{ text: 'Home', link: '/' }],
      title: (params) => params.pageTitle || 'Vendor Management',
      subTitle: 'Vendor Management',
    },
    {
      path: '/vendor/management/:mainTab/:entityId/:subTab',
      component: VendorManagementContainer,
      props: true,
      breadcrumbs: [{ text: 'Home', link: '/' }],
      title: (params) => params.pageTitle || 'Vendor Management',
      subTitle: 'Vendor Management',
    },
    {
      path: '/login',
      component: Login,
      title: 'Login/Create Account',
      subTitle: 'fullCircle',
      meta: { requiresAuth: false },
    },
    {
      path: '/messages',
      component: SecureMessagesContainer,
      title: (params) => params.pageTitle,
      subTitle: 'fullCircle',
    },
    {
      path: '/mfa',
      component: Mfa,
      title: 'Multi-Factor Authentication',
      subTitle: 'fullCircle',
      meta: { requiresAuth: false },
    },
    {
      path: '/pci',
      component: PciContainer,
      breadcrumbs: [{ text: 'Home', link: '/' }],
      title: (params) => params.pageTitle,
      subTitle: 'PCI Compliance',
    },
    {
      path: '/reports',
      component: ReportsContainer,
      breadcrumbs: [{ text: 'Home', link: '/' }],
      title: 'Reports',
      subTitle: 'Reporting',
    },
    {
      path: '/reports/:tab',
      component: ReportsContainer,
      props: true,
      breadcrumbs: [
        { text: 'Home', link: '/' },
        { text: 'Reporting', link: '/reports' },
      ],
      title: 'Reports',
      subTitle: 'Reporting',
    },
    {
      path: '/reports/:tab/:tabId',
      component: ReportsContainer,
      props: true,
      breadcrumbs: [
        { text: 'Home', link: '/' },
        { text: 'Reporting', link: '/reports' },
      ],
      title: 'Reports',
      subTitle: 'Reporting',
    },
    {
      path: '/reports/:tab/:tabId/:subTab',
      component: ReportsContainer,
      props: true,
      breadcrumbs: [
        { text: 'Home', link: '/' },
        { text: 'Reporting', link: '/reports' },
      ],
      title: 'Reports',
      subTitle: 'Reporting',
    },
    {
      path: '/selfassessments',
      component: SelfAssessmentsContainer,
      breadcrumbs: [{ text: 'Home', link: '/' }],
      title: 'Self Assessments',
      subTitle: 'Self Assessments',
    },
    {
      path: '/selfassessments/:assessmentId',
      component: SelfAssessmentsContainer,
      props: true,
      breadcrumbs: [{ text: 'Home', link: '/' }],
      title: 'Self Assessments',
      subTitle: 'Self Assessments',
    },
    {
      path: '/selfassessments/qbuilder/:entityId',
      component: SelfAssessmentsContainer,
      props: true,
      breadcrumbs: [{ text: 'Home', link: '/' }],
      title: 'Self Assessments',
      subTitle: 'Self Assessments',
    },
    {
      path: '/selfassessments/:assessmentId/:sectionId',
      component: SelfAssessmentsContainer,
      props: true,
      breadcrumbs: [{ text: 'Home', link: '/' }],
      title: 'Self Assessments',
      subTitle: 'Self Assessments',
    },
    {
      path: '/selfassessments/:assessmentId/:sectionId/:questionId',
      component: SelfAssessmentsContainer,
      props: true,
      breadcrumbs: [{ text: 'Home', link: '/' }],
      title: 'Self Assessments',
      subTitle: 'Self Assessments',
    },
    {
      path: '/settings',
      component: SettingsContainer,
      props: true,
      breadcrumbs: [{ text: 'Home', link: '/' }],
      title: (params) => params.pageTitle,
      subTitle: 'Settings',
    },
    {
      path: '/settings/:tab',
      component: SettingsContainer,
      props: true,
      breadcrumbs: [
        { text: 'Home', link: '/' },
        { text: 'Settings', link: '/settings' },
      ],
      title: (params) => params.pageTitle,
      subTitle: 'Settings',
    },
    {
      path: '/settings/:tab/:tabId',
      component: SettingsContainer,
      props: true,
      breadcrumbs: [
        { text: 'Home', link: '/' },
        { text: 'Settings', link: '/settings' },
      ],
      title: (params) => params.pageTitle,
      subTitle: 'Settings',
    },
    {
      path: '/verification/:type',
      component: AuthVerification,
      props: true,
      breadcrumbs: [{ text: 'Home', link: '/' }],
      title: 'Verification',
      subTitle: 'fullCircle',
      meta: { requiresAuth: false },
    },
    {
      path: '/eula',
      component: Eula,
      props: true,
      breadcrumbs: [{ text: 'Home', link: '/' }],
      title: 'Terms of Service',
      subTitle: 'fullCircle',
      meta: { requiresAuth: false },
    },
    {
      path: '/verify/password/reset/:email/:hash',
      component: ConfirmResetPassword,
      props: true,
      breadcrumbs: [{ text: 'Home', link: '/' }],
      title: 'Reset Password',
      subTitle: 'fullCircle',
      meta: { requiresAuth: false },
    },
    {
      path: '/templates',
      component: TemplateCenterHome,
      breadcrumbs: [{ text: 'Home', link: '/' }],
      title: 'Templates',
      subTitle: 'Template Center',
    },
    {
      path: '/templates/admin',
      component: TemplateCenterHome,
      breadcrumbs: [
        { text: 'Home', link: '/' },
        { text: 'Template Center', link: '/templates' },
      ],
      title: 'Template Center Administration',
      subTitle: 'Template Center',
    },
    {
      path: '/vulnerability',
      component: VulnerabilityHome,
      props: true,
      breadcrumbs: [{ text: 'Home', link: '/' }],
      title: 'Vulnerability Management',
      subTitle: 'Vulnerability Management',
    },
    {
      path: '/vulnerability/reports/sla',
      component: VulnerabilityReportSLA,
      breadcrumbs: [
        { text: 'Home', link: '/' },
        { text: 'Vulnerability Management', link: '/vulnerability' },
      ],
      title: 'Service Level Agreements (SLA)',
      subTitle: 'Vulnerability Management',
    },
    {
      path: '/vulnerability/list/:tab',
      component: VulnerabilityHome,
      props: true,
      breadcrumbs: [
        { text: 'Home', link: '/' },
        { text: 'Vulnerability Management', link: '/vulnerability' },
      ],
      title: (params) => params.pageTitle,
      subTitle: 'Vulnerability Management',
    },
    {
      path: '/vulnerability/remote/:remoteId',
      component: RemoteNodeDetail,
      props: true,
      breadcrumbs: [
        { text: 'Home', link: '/' },
        { text: 'Vulnerability Management', link: '/vulnerability' },
        { text: 'Remote Access', link: '/vulnerability/list/remote' },
      ],
      title: 'Remote Access',
      subTitle: 'Vulnerability Management',
    },
    {
      path: '/vulnerability/remote/:nodeId/command/:commandId',
      component: RemoteNodeCommandDetail,
      props: true,
      breadcrumbs: [
        { text: 'Home', link: '/' },
        { text: 'Vulnerability Management', link: '/vulnerability' },
        { text: 'Remote Access', link: '/vulnerability/list/remote' },
        {
          text: `Back to Remote Node`,
          link: (params) => `/vulnerability/remote/${params.nodeId}`,
        },
      ],
      title: 'Remote Node',
      subTitle: 'Vulnerability Management',
    },
    {
      path: '/vulnerability/:scanId',
      component: VulnerabilityHome,
      props: true,
      breadcrumbs: [
        { text: 'Home', link: '/' },
        { text: 'Vulnerability Management', link: '/vulnerability' },
      ],
      title: (params) => params.pageTitle,
      subTitle: 'Vulnerability Management',
    },
    {
      path: '/vulnerability/:scanId/item/:itemId',
      component: VulnerabilityHome,
      props: true,
      breadcrumbs: [
        { text: 'Home', link: '/' },
        { text: 'Vulnerability Management', link: '/vulnerability' },
      ],
      title: (params) => params.pageTitle,
      subTitle: 'Vulnerability Management',
    },
    {
      path: '/user/:user_id',
      component: UserPublicProfileContainer,
      props: true,
      breadcrumbs: [{ text: 'Home', link: '/' }],
      title: (params) => params.pageTitle,
      subTitle: 'User Profile',
    },
    {
      path: '/controls',
      component: MyControls,
      props: { tab: 'dashboard' },
      breadcrumbs: [{ text: 'Home', link: '/' }],
      title: (params) => params.pageTitle || 'Controls',
      subTitle: 'Controls',
    },
    {
      path: '/controls/:tab',
      component: MyControls,
      props: true,
      breadcrumbs: [{ text: 'Home', link: '/' }],
      title: (params) => params.pageTitle || 'Controls',
      subTitle: 'Controls',
    },
    {
      path: '/controls/controls/:controlId',
      component: MyControls,
      props: (route) => ({
        tab: 'controls',
        controlId: route.params.controlId,
      }),
      breadcrumbs: [
        { text: 'Home', link: '/' },
        { text: 'Controls', link: '/controls' },
      ],
      title: (params) => params.pageTitle || 'Controls',
      subTitle: 'Controls',
    },
    {
      path: '/controls/controls/:controlId/:subTab',
      component: MyControls,
      props: (route) => ({
        tab: 'controls',
        controlId: route.params.controlId,
        subTab: route.params.subTab,
      }),
      breadcrumbs: [
        { text: 'Home', link: '/' },
        { text: 'Controls', link: '/controls' },
        { text: 'Control', link: '/controls/controls/:controlId' },
      ],
      title: (params) => params.pageTitle || 'Controls',
      subTitle: 'Controls',
    },
    {
      path: '/controls/controls/:controlId/:subTab/:subTabId',
      component: MyControls,
      props: (route) => ({
        tab: 'controls',
        controlId: route.params.controlId,
        subTab: route.params.subTab,
        subTabId: route.params.subTabId,
      }),
      breadcrumbs: [
        { text: 'Home', link: '/' },
        { text: 'Controls', link: '/controls' },
        { text: 'Control', link: '/controls/controls/:controlId' },
        { text: 'Control', link: '/controls/controls/:controlId/:subTab' },
      ],
      title: (params) => params.pageTitle || 'Controls',
      subTitle: 'Controls',
    },
    {
      path: '/controls/evidence/:evidenceId',
      component: MyControls,
      props: (route) => ({
        tab: 'evidence',
        evidenceId: route.params.evidenceId,
      }),
      breadcrumbs: [{ text: 'Home', link: '/' }],
      title: (params) => params.pageTitle || 'Controls',
      subTitle: 'Controls',
    },
    {
      path: '/controls/evidence/:evidenceId/:subTab',
      component: MyControls,
      props: (route) => ({
        tab: 'evidence',
        evidenceId: route.params.evidenceId,
        subTab: route.params.subTab,
      }),
      breadcrumbs: [
        { text: 'Home', link: '/' },
        { text: 'Controls', link: '/controls' },
        { text: 'Evidence', link: '/controls/evidence/:evidenceId' },
      ],
      title: (params) => params.pageTitle || 'Controls',
      subTitle: 'Controls',
    },
    {
      path: '/controls/evidence/:evidenceId/:subTab/:subTabId',
      component: MyControls,
      props: (route) => ({
        tab: 'evidence',
        evidenceId: route.params.evidenceId,
        subTab: route.params.subTab,
        subTabId: route.params.subTabId,
      }),
      breadcrumbs: [
        { text: 'Home', link: '/' },
        { text: 'Controls', link: '/controls' },
        { text: 'Evidence', link: '/controls/evidence/:evidenceId' },
        {
          text: 'Evidence',
          link: '/controls/evidence/:evidenceId/:subTab',
        },
      ],
      title: (params) => params.pageTitle || 'Controls',
      subTitle: 'Controls',
    },
    {
      path: '/controls/frameworks/:frameworkMapId',
      component: MyControls,
      props: (route) => ({
        tab: 'frameworks',
        frameworkMapId: route.params.frameworkMapId,
      }),
      breadcrumbs: [
        { text: 'Home', link: '/' },
        { text: 'Controls', link: '/controls' },
        { text: 'Frameworks', link: '/controls/frameworks' },
      ],
      title: (params) => params.pageTitle || 'Controls',
      subTitle: 'Controls',
    },
    {
      path: '/controls/frameworks/:frameworkMapId/controls',
      component: MyControls,
      props: (route) => ({
        tab: 'frameworks',
        frameworkMapId: route.params.frameworkMapId,
      }),
      breadcrumbs: [
        { text: 'Home', link: '/' },
        { text: 'Controls', link: '/controls' },
        { text: 'Frameworks', link: '/controls/frameworks' },
        {
          text: 'Framework Controls',
          link: '/controls/frameworks/:frameworkMapId',
        },
      ],
      title: (params) => params.pageTitle || 'Controls',
      subTitle: 'Controls',
    },
    {
      path: '/controls/frameworks/preview/:frameworkVersionId',
      component: MyControls,
      props: (route) => ({
        tab: 'frameworks',
        frameworkVersionId: route.params.frameworkVersionId,
        isPreview: true,
      }),
      breadcrumbs: [
        { text: 'Home', link: '/' },
        { text: 'Controls', link: '/controls' },
        { text: 'Frameworks', link: '/controls/frameworks' },
      ],
      title: (params) => params.pageTitle || 'Controls',
      subTitle: 'Controls',
    },
    {
      path: '/controls/frameworks/:frameworkMapId/controls/:controlId',
      component: MyControls,
      props: (route) => ({
        tab: 'frameworks',
        frameworkMapId: route.params.frameworkMapId,
        controlId: route.params.controlId,
      }),
      breadcrumbs: [
        { text: 'Home', link: '/' },
        { text: 'Controls', link: '/controls' },
        { text: 'Frameworks', link: '/controls/frameworks' },
        {
          text: 'Framework Controls',
          link: '/controls/frameworks/:frameworkMapId',
        },
        {
          text: 'Framework Controls',
          link: '/controls/frameworks/:frameworkMapId/controls',
        },
      ],
      title: (params) => params.pageTitle || 'Controls',
      subTitle: 'Controls',
    },
    {
      path: '/controls/frameworks/:frameworkMapId/controls/:controlId/:subTab',
      component: MyControls,
      props: (route) => ({
        tab: 'frameworks',
        frameworkMapId: route.params.frameworkMapId,
        controlId: route.params.controlId,
        subTab: route.params.subTab,
      }),
      breadcrumbs: [
        { text: 'Home', link: '/' },
        { text: 'Controls', link: '/controls' },
        { text: 'Frameworks', link: '/controls/frameworks' },
        {
          text: 'Framework Controls',
          link: '/controls/frameworks/:frameworkMapId',
        },
        {
          text: 'Framework Controls',
          link: '/controls/frameworks/:frameworkMapId/controls',
        },
      ],
      title: (params) => params.pageTitle || 'Controls',
      subTitle: 'Controls',
    },
    {
      path: '/controls/frameworks/:frameworkMapId/controls/:controlId/:subTab/:subTabId',
      component: MyControls,
      props: (route) => ({
        tab: 'frameworks',
        frameworkMapId: route.params.frameworkMapId,
        controlId: route.params.controlId,
        subTab: route.params.subTab,
        subTabId: route.params.subTabId,
      }),
      breadcrumbs: [
        { text: 'Home', link: '/' },
        { text: 'Controls', link: '/controls' },
        { text: 'Frameworks', link: '/controls/frameworks' },
        {
          text: 'Framework Controls',
          link: '/controls/frameworks/:frameworkMapId',
        },
        {
          text: 'Framework Controls',
          link: '/controls/frameworks/:frameworkMapId/controls',
        },
      ],
      title: (params) => params.pageTitle || 'Controls',
      subTitle: 'Controls',
    },
    {
      path: '/styles',
      component: StyleGuide,
      breadcrumbs: [{ text: 'Home', link: '/' }],
      title: (params) => params.pageTitle || 'Style Guide',
      subTitle: 'Style Guide',
    },
    {
      path: '/styles/:tab',
      component: StyleGuide,
      breadcrumbs: [{ text: 'Home', link: '/' }],
      title: (params) => params.pageTitle,
      subTitle: 'Style Guide',
    },
    {
      path: '/subscription/update',
      component: SubscriptionUpdate,
      props: true,
      breadcrumbs: [{ text: 'Home', link: '/' }],
      title: 'Subscription Update',
      subTitle: 'fullCircle',
    },
    {
      path: '/policies',
      component: WikiContainer,
      breadcrumbs: [{ text: 'Home', link: '/' }],
      title: (params) => params.pageTitle || 'Policies',
      subTitle: 'Policies',
    },
    {
      path: '/policies/page/:pageId/:tab',
      component: WikiContainer,
      props: true,
      breadcrumbs: [{ text: 'Home', link: '/' }],
      title: (params) => params.pageTitle || 'Policies',
      subTitle: 'Policies',
    },
    {
      path: '/policies/page/:pageId',
      component: WikiContainer,
      props: true,
      breadcrumbs: [{ text: 'Home', link: '/' }],
      title: (params) => params.pageTitle || 'Policies',
      subTitle: 'Policies',
    },
    {
      path: '/:tab/:recordId/:subTab',
      component: DashboardContainer,
      props: true,
      breadcrumbs: [{ text: 'Home', link: '/' }],
      title: (params) => params.pageTitle || 'fullCircle',
      subTitle: 'fullCircle',
    },
    {
      path: '/:tab/:recordId',
      component: DashboardContainer,
      props: true,
      breadcrumbs: [{ text: 'Home', link: '/' }],
      title: (params) => params.pageTitle || 'fullCircle',
      subTitle: 'fullCircle',
    },
    {
      path: '/:tab',
      component: DashboardContainer,
      props: true,
      breadcrumbs: [{ text: 'Home', link: '/' }],
      title: (params) => params.pageTitle || 'fullCircle',
      subTitle: 'fullCircle',
    },
    {
      path: '/',
      component: DashboardContainer,
      breadcrumbs: [{ text: 'Home', link: '/' }],
      title: 'Home',
      subTitle: 'fullCircle',
    },
    {
      path: '/(.*)*',
      component: DashboardContainer,
      breadcrumbs: [{ text: 'Home', link: '/' }],
      title: 'Home',
      subTitle: 'fullCircle',
    },
  ]
}
