// other endpoints to merge
import Reporting from './assessments/reporting'
import Testing from './assessments/testing'

import Utilities from '@risk3sixty/utilities'
import R3sFetch from './R3sFetch'
import { handleFetchResponse } from './ApiHelpers'

export default {
  ...Reporting,
  ...Testing,

  async getEngagementTypes() {
    const response = await R3sFetch(`/api/1.0/assessments/engagement/types`)
    return await handleFetchResponse(response)
  },

  async getAvailableRollForwardTypes(engagementType) {
    const response = await R3sFetch(
      `/api/1.0/assessments/available/migration/engagement/types?engagementType=${engagementType}`
    )
    return await handleFetchResponse(response)
  },

  async getGapTypes() {
    const response = await R3sFetch(`/api/1.0/assessments/gap/types/get`)
    return await handleFetchResponse(response)
  },

  async statuses() {
    const response = await R3sFetch(`/api/1.0/assessments/statuses`)
    return await handleFetchResponse(response)
  },

  async projectForDashboard(page = 1, perPage = 5, onlyFavorites = false) {
    const response = await R3sFetch(
      `/api/1.0/assessments/dashboard/projects/get?page=${page || ''}&perPage=${
        perPage || ''
      }&favs=${onlyFavorites || ''}`
    )
    return await handleFetchResponse(response)
  },

  async getIndependence(teamId = false) {
    const response = await R3sFetch(
      `/api/1.0/assessments/independence/get?teamId=${teamId || ''}`
    )
    return await handleFetchResponse(response)
  },

  async getProjects({ status, type, teamId, moduleType } = {}) {
    const response = await R3sFetch(
      `/api/1.0/assessments/projects/all?status=${status || ''}&type=${
        type || ''
      }&module=${moduleType || ''}&teamId=${teamId || ''}`
    )
    return await handleFetchResponse(response)
  },

  async getProjectNames() {
    const response = await R3sFetch(`/api/1.0/assessments/projects/names/get`)
    return await handleFetchResponse(response)
  },

  async getProject(id, allDataType = false, filters = {}) {
    const response = await R3sFetch(
      `/api/1.0/assessments/project/get?id=${id}&dataType=${
        allDataType || ''
      }&filters=${encodeURIComponent(JSON.stringify(filters))}`
    )
    return await handleFetchResponse(response)
  },

  async getProjectDashboardData(id, filters = {}, type) {
    filters = Utilities.Strings.qsSerialize(filters)
    const response = await R3sFetch(
      `/api/1.0/assessments/project/dashboard/get?id=${id}${
        filters ? '&' + filters : ''
      }${type ? '&type=' + type : ''}`
    )
    return await handleFetchResponse(response)
  },

  async getCategoriesAndRequests(id, allDataType = false) {
    const response = await R3sFetch(
      `/api/1.0/assessments/project/categories/requests/get?id=${id}&dataType=${
        allDataType || ''
      }`
    )
    return await handleFetchResponse(response)
  },

  async getCategory(id) {
    const response = await R3sFetch(
      `/api/1.0/assessments/project/category/get?id=${id}`
    )
    return await handleFetchResponse(response)
  },

  async getCategoryDependencies(id) {
    const response = await R3sFetch(
      `/api/1.0/assessments/project/category/dependencies?id=${id}`
    )
    return await handleFetchResponse(response)
  },

  async getRequest(id) {
    const response = await R3sFetch(
      `/api/1.0/assessments/project/request/get?id=${id}`
    )
    return await handleFetchResponse(response)
  },

  async getControl(id) {
    const response = await R3sFetch(
      `/api/1.0/assessments/project/control/get?id=${id}`
    )
    return await handleFetchResponse(response)
  },

  async getControlTestInfo(controlId, testInfoId) {
    const response = await R3sFetch(
      `/api/1.0/assessments/project/control/testing/get?controlId=${controlId}${
        testInfoId ? `&testInfoId=${testInfoId}` : ''
      }`
    )
    return await handleFetchResponse(response)
  },

  async getControlsByIds(controlIds) {
    const response = await R3sFetch(`/api/1.0/assessments/controls/get/by/id`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ controlIds }),
    })
    return await handleFetchResponse(response)
  },

  async getControlsAndRequestsByControlIds(controlIds) {
    const response = await R3sFetch(
      `/api/1.0/assessments/controls/requests/get/by/id`,
      {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ controlIds }),
      }
    )
    return await handleFetchResponse(response)
  },

  async getKeyContacts(projectId) {
    const response = await R3sFetch(
      `/api/1.0/assessments/contacts/get?id=${projectId || ''}`
    )
    return await handleFetchResponse(response)
  },

  async getSystemComponents(projectId) {
    const response = await R3sFetch(
      `/api/1.0/assessments/system/components/get?id=${projectId || ''}`
    )
    return await handleFetchResponse(response)
  },

  async getControls(projectId) {
    const response = await R3sFetch(
      `/api/1.0/assessments/controls/get?id=${projectId}`
    )
    return await handleFetchResponse(response)
  },

  async getRequests(projectId) {
    const response = await R3sFetch(
      `/api/1.0/assessments/requests/get?id=${projectId}`
    )
    return await handleFetchResponse(response)
  },

  async getControlsAndTestingInfo(projectId) {
    const response = await R3sFetch(
      `/api/1.0/assessments/controls/testing/get?id=${projectId}`
    )
    return await handleFetchResponse(response)
  },

  async resetProjectRefNums(projectId) {
    const response = await R3sFetch(
      `/api/1.0/assessments/project/requests/refnum/reset`,
      {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ projectId }),
      }
    )
    return await handleFetchResponse(response)
  },

  async addRequestAttachment(projectId, info) {
    const response = await R3sFetch(
      `/api/1.0/assessments/project/request/attachment/add`,
      {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ projectId, ...info }),
      }
    )
    return await handleFetchResponse(response)
  },

  async downloadRequestAttachment(projectId, info) {
    const response = await R3sFetch(
      `/api/1.0/assessments/project/request/attachment/download`,
      {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },

        // Commenting out the projectId here since that's what drives
        // whether an action can be taken based on project status (from projectId).
        // Us and users should be able to download evidence for
        // archived projects though, so omitting it here should resolve.
        body: JSON.stringify({ /*projectId, */ ...info }),
      }
    )
    return await handleFetchResponse(response)
  },

  async deleteRequestAttachment(projectId, info) {
    const response = await R3sFetch(
      `/api/1.0/assessments/project/request/attachment/delete`,
      {
        method: 'DELETE',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ projectId, ...info }),
      }
    )
    return await handleFetchResponse(response)
  },

  async downloadControlsTable(projectId, hitrustFormat = false) {
    const response = await R3sFetch(
      `/api/1.0/assessments/controls/table/download`,
      {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ projectId, hitrustFormat }),
      }
    )
    return await handleFetchResponse(response)
  },

  async addRequestComment(info) {
    const response = await R3sFetch(
      `/api/1.0/assessments/project/request/comment/add`,
      {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(info),
      }
    )
    return await handleFetchResponse(response)
  },

  async addControlReviewComment(info) {
    const response = await R3sFetch(
      `/api/1.0/assessments/project/control/review/comment/add`,
      {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(info),
      }
    )
    return await handleFetchResponse(response)
  },

  async bulkUpdateRequestAssignments(requests) {
    const response = await R3sFetch(
      `/api/1.0/assessments/project/request/assignments/update`,
      {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(requests),
      }
    )
    return await handleFetchResponse(response)
  },

  async updateRequestAssignment(projectId, info) {
    const response = await R3sFetch(
      `/api/1.0/assessments/project/request/assignment/update`,
      {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ projectId, ...info }),
      }
    )
    return await handleFetchResponse(response)
  },

  async sendRequestUploadUserEmail(projectId, requestId, userId) {
    const response = await R3sFetch(
      `/api/1.0/assessments/project/request/upload/users/email`,
      {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ projectId, requestId, userId }),
      }
    )
    return await handleFetchResponse(response)
  },

  async bulkUpdateControlAssignments(controls) {
    const response = await R3sFetch(
      `/api/1.0/assessments/project/control/assignments/update`,
      {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(controls),
      }
    )
    return await handleFetchResponse(response)
  },

  async updateControlAssignment(projectId, info) {
    const response = await R3sFetch(
      `/api/1.0/assessments/project/control/assignment/update`,
      {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ projectId, ...info }),
      }
    )
    return await handleFetchResponse(response)
  },

  async deleteKeyContactAssignment(assignmentId) {
    const response = await R3sFetch(
      `/api/1.0/assessments/contact/assignment/remove`,
      {
        method: 'DELETE',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ id: assignmentId }),
      }
    )
    return await handleFetchResponse(response)
  },

  async updateKeyContact(info) {
    const response = await R3sFetch(`/api/1.0/assessments/contact/update`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(info),
    })
    return await handleFetchResponse(response)
  },

  async updateSystemComponent(info) {
    const response = await R3sFetch(
      `/api/1.0/assessments/system/component/update`,
      {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(info),
      }
    )
    return await handleFetchResponse(response)
  },

  async createProject(info) {
    const response = await R3sFetch(`/api/1.0/assessments/project/create`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(info),
    })
    return await handleFetchResponse(response)
  },

  async bulkCreateProjectCategories(info) {
    const response = await R3sFetch(
      `/api/1.0/assessments/project/categories/bulk/create`,
      {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(info),
      }
    )
    return await handleFetchResponse(response)
  },

  async createProjectCategory(info) {
    const response = await R3sFetch(
      `/api/1.0/assessments/project/category/create`,
      {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(info),
      }
    )
    return await handleFetchResponse(response)
  },

  async createProjectControl(projectId, info) {
    const response = await R3sFetch(
      `/api/1.0/assessments/project/control/create`,
      {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ projectId, ...info }),
      }
    )
    return await handleFetchResponse(response)
  },

  async createProjectRequest(projectId, info) {
    const response = await R3sFetch(
      `/api/1.0/assessments/project/request/create`,
      {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ projectId, ...info }),
      }
    )
    return await handleFetchResponse(response)
  },

  async bulkCreateProjectItems(projectId, type, items) {
    let response
    switch (type) {
      case 'testing':
        response = await R3sFetch(
          `/api/1.0/assessments/project/controls/bulk/create`,
          {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ projectId, controls: items }),
          }
        )
        break
      case 'evidence':
        response = await R3sFetch(
          `/api/1.0/assessments/project/requests/bulk/create`,
          {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ projectId, requests: items }),
          }
        )
        break
    }
    return await handleFetchResponse(response)
  },

  async bulkDeleteControls(controlIdsToDelete) {
    const response = await R3sFetch(
      `/api/1.0/assessments/project/controls/delete`,
      {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ controlIdsToDelete }),
      }
    )
    return await handleFetchResponse(response)
  },

  async bulkDeleteRequests(requestIdsToDelete) {
    const response = await R3sFetch(
      `/api/1.0/assessments/project/requests/delete`,
      {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ requestIdsToDelete }),
      }
    )
    return await handleFetchResponse(response)
  },

  async updateRequestOrdering(projectId, categoryId, requestIds) {
    const response = await R3sFetch(
      `/api/1.0/assessments/project/requests/reorder`,
      {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ projectId, categoryId, requestIds }),
      }
    )
    return await handleFetchResponse(response)
  },

  async linkControlToRequest(
    projectId,
    controlId,
    requestId,
    removeLink = false
  ) {
    const response = await R3sFetch(
      `/api/1.0/assessments/project/control/request/link`,
      {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ projectId, controlId, requestId, removeLink }),
      }
    )
    return await handleFetchResponse(response)
  },

  async linkFileToControl({ projectId, controlId, fileUploadsId, removeLink }) {
    const response = await R3sFetch(
      `/api/1.0/assessments/project/control/file/link`,
      {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          projectId,
          controlId,
          fileUploadsId,
          removeLink,
        }),
      }
    )
    return await handleFetchResponse(response)
  },

  async updateIndependence(isIndependent, teamId = false) {
    const response = await R3sFetch(
      `/api/1.0/assessments/independence/update`,
      {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ independent: isIndependent, teamId }),
      }
    )
    return await handleFetchResponse(response)
  },

  async createTemplateFromProject(projectId, orgId) {
    const response = await R3sFetch(
      `/api/1.0/assessments/project/template/create`,
      {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ projectId, orgId }),
      }
    )
    return await handleFetchResponse(response)
  },

  async createProjectFromTemplate(
    templateId,
    {
      projectId,
      newName,
      newAuditStart,
      newAuditEnd,
      newDueDate,
      targetTeamId,
    } = {}
  ) {
    const response = await R3sFetch(
      `/api/1.0/assessments/project/create/from/template`,
      {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          targetTeamId,
          templateId,
          projectId,
          newName,
          newAuditStart,
          newAuditEnd,
          newDueDate,
        }),
      }
    )
    return await handleFetchResponse(response)
  },

  async rollForwardProject(
    projectId,
    {
      includeAssignments,
      includeFiles,
      includeTags,
      includeTesting,
      newName,
      newAuditStart,
      newAuditEnd,
      newDueDate,
    } = {}
  ) {
    const response = await R3sFetch(
      `/api/1.0/assessments/project/rollforward`,
      {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          id: projectId,
          projectId,
          includeAssignments,
          includeFiles,
          includeTags,
          includeTesting,
          newName,
          newAuditStart,
          newAuditEnd,
          newDueDate,
        }),
      }
    )
    return await handleFetchResponse(response)
  },

  async getProjectSchedule(projectId, includeDeleted = false) {
    const response = await R3sFetch(
      `/api/1.0/assessments/project/schedule/get?id=${
        projectId || ''
      }&includeDeleted=${includeDeleted || ''}`
    )
    return await handleFetchResponse(response)
  },

  async updateProjectScheduleItem(projectId, item, deleteItem = false) {
    const response = await R3sFetch(
      `/api/1.0/assessments/project/schedule/item/update`,
      {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ id: projectId, deleteItem, item }),
      }
    )
    return await handleFetchResponse(response)
  },

  async getProjectTeamMembers(projectId) {
    const response = await R3sFetch(
      `/api/1.0/assessments/project/team/get?id=${projectId || ''}`
    )
    return await handleFetchResponse(response)
  },

  async updateProjectTeamMembers(projectId, teamMembers) {
    const response = await R3sFetch(
      `/api/1.0/assessments/project/team/update`,
      {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ projectId, teamMembers }),
      }
    )
    return await handleFetchResponse(response)
  },

  async updateProjectScheduleCategory(info) {
    const response = await R3sFetch(
      `/api/1.0/assessments/project/schedule/category/update`,
      {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(info),
      }
    )
    return await handleFetchResponse(response)
  },

  async getKeyFindings(projectId) {
    const response = await R3sFetch(
      `/api/1.0/assessments/key/findings/get?id=${projectId || ''}`
    )
    return await handleFetchResponse(response)
  },

  async updateKeyFindings(projectId, findings) {
    const response = await R3sFetch(
      `/api/1.0/assessments/key/findings/update`,
      {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ id: projectId, findings }),
      }
    )
    return await handleFetchResponse(response)
  },

  async logRequestTagUpdate(projectId, requestId, tagId, operation = 'add') {
    const response = await R3sFetch(
      `/api/1.0/assessments/project/request/tag/update`,
      {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ projectId, requestId, tagId, operation }),
      }
    )
    return await handleFetchResponse(response)
  },

  /** Deprecated FC-1233*/
  async bulkUpload(projectId, filename, type = 'requests') {
    const response = await R3sFetch(`/api/1.0/assessments/bulk/upload`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ projectId, filename, type }),
    })
    return await handleFetchResponse(response)
  },

  async bulkUploadHitrustControls(projectId, filename) {
    const response = await R3sFetch(
      `/api/1.0/assessments/bulk/upload/hitrust`,
      {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ projectId, filename }),
      }
    )
    return await handleFetchResponse(response)
  },

  async getProjectCriteria(projectId) {
    const response = await R3sFetch(
      `/api/1.0/assessments/project/criteria/getall?id=${projectId || ''}`
    )
    return await handleFetchResponse(response)
  },

  async getCriteriaRecord(criteriaId) {
    const response = await R3sFetch(
      `/api/1.0/assessments/project/criteria/get?id=${criteriaId || ''}`
    )
    return await handleFetchResponse(response)
  },

  async updateCriteriaRecord(info) {
    const response = await R3sFetch(
      `/api/1.0/assessments/project/criteria/update`,
      {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(info),
      }
    )
    return await handleFetchResponse(response)
  },

  async updateCriteriaRecords(criteriaList) {
    const response = await R3sFetch(
      `/api/1.0/assessments/project/criteria/update/bulk`,
      {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(criteriaList),
      }
    )
    return await handleFetchResponse(response)
  },

  async mapCriteriaToControl(
    { projectId, controlId, criteriaId },
    deleteMapping = false
  ) {
    const response = await R3sFetch(
      `/api/1.0/assessments/control/criteria/map`,
      {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          projectId,
          controlId,
          criteriaId,
          deleteMapping,
        }),
      }
    )
    return await handleFetchResponse(response)
  },

  async populateCosoCriteria(projectId) {
    const response = await R3sFetch(
      `/api/1.0/assessments/control/criteria/populate`,
      {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ projectId }),
      }
    )
    return await handleFetchResponse(response)
  },

  async getRequestsAssignedToUser(page, perPage) {
    const response = await R3sFetch(
      `/api/1.0/assessments/requests/assigned/to/user?page=${
        page || ''
      }&perPage=${perPage || ''}`
    )
    return await handleFetchResponse(response)
  },

  async importAsanaSchedule(info) {
    const response = await R3sFetch(
      `/api/1.0/assessments/project/schedule/asana/import`,
      {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(info),
      }
    )
    return await handleFetchResponse(response)
  },

  async unlinkAsanaSchedule(projectId) {
    const response = await R3sFetch(
      `/api/1.0/assessments/project/schedule/asana/unlink`,
      {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ id: projectId }),
      }
    )
    return await handleFetchResponse(response)
  },

  async forceAsanaScheduleSync(projectId) {
    const response = await R3sFetch(
      `/api/1.0/assessments/project/schedule/asana/sync`,
      {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ projectId }),
      }
    )
    return await handleFetchResponse(response)
  },

  async downloadProject(info) {
    const response = await R3sFetch(`/api/1.0/assessments/project/download`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(info),
    })
    return await handleFetchResponse(response)
  },

  async downloadTestingTables(projectId) {
    const response = await R3sFetch(
      `/api/1.0/assessments/testing/tables/export`,
      {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ projectId }),
      }
    )
    return await handleFetchResponse(response)
  },

  async downloadAllRequestsAndEvidence(
    projectId,
    requestId,
    oneFolder = false
  ) {
    const response = await R3sFetch(
      `/api/1.0/assessments/requests/full/download?id=${
        projectId || ''
      }&request_id=${requestId || ''}&oneFolder=${oneFolder}`
    )
    return await handleFetchResponse(response)
  },

  async getChartingKpis(projectId, filters = {}) {
    const response = await R3sFetch(
      `/api/1.0/assessments/charting/get?id=${
        projectId || ''
      }&filters=${encodeURIComponent(JSON.stringify(filters))}`
    )
    return await handleFetchResponse(response)
  },

  async getFormalFindings(projectIds) {
    const response = await R3sFetch(
      `/api/1.0/assessments/formal/findings/get`,
      {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ ids: projectIds }),
      }
    )
    return await handleFetchResponse(response)
  },

  async lockFormalFinding(projectId, findingId, isLocked = true) {
    const response = await R3sFetch(
      `/api/1.0/assessments/formal/finding/lock`,
      {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ projectId, findingId, isLocked }),
      }
    )
    return await handleFetchResponse(response)
  },

  async updateFindingLevel(level) {
    const response = await R3sFetch(
      `/api/1.0/assessments/formal/findings/status/save`,
      {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ level }),
      }
    )
    return await handleFetchResponse(response)
  },

  async saveSingleFormalFinding(projectId, finding) {
    const response = await R3sFetch(
      `/api/1.0/assessments/formal/finding/save`,
      {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ projectId, finding }),
      }
    )
    return await handleFetchResponse(response)
  },

  async deleteFormalFinding(projectId, findingId) {
    const response = await R3sFetch(
      `/api/1.0/assessments/formal/finding/delete`,
      {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ projectId, findingId }),
      }
    )
    return await handleFetchResponse(response)
  },

  async changeRequestLockStatus(projectId, requestId, isLocked = true) {
    const response = await R3sFetch(
      `/api/1.0/assessments/project/request/change/lock`,
      {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          projectId,
          id: requestId,
          lockStatus: isLocked,
        }),
      }
    )
    return await handleFetchResponse(response)
  },

  async cloneProjectCategory(
    categoryId,
    projectId,
    targetTeamId,
    targetProjectId,
    filters
  ) {
    const response = await R3sFetch(
      `/api/1.0/assessments/projects/category/clone`,
      {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          categoryId,
          projectId,
          targetTeamId,
          targetProjectId,
          filters,
        }),
      }
    )
    return await handleFetchResponse(response)
  },

  async cloneProjectControls(
    projectId,
    targetTeamId,
    targetProjectId,
    filters,
    controlsToClone,
    keepCategories
  ) {
    const response = await R3sFetch(
      `/api/1.0/assessments/project/controls/clone`,
      {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          projectId,
          targetTeamId,
          targetProjectId,
          filters,
          controlsToClone,
          keepCategories,
        }),
      }
    )
    return await handleFetchResponse(response)
  },

  async cloneRequest(projectId, requestId) {
    const response = await R3sFetch(`/api/1.0/assessments/request/copy`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        projectId,
        id: requestId,
      }),
    })
    return await handleFetchResponse(response)
  },

  async getMasterControls() {
    const response = await R3sFetch(`/api/1.0/assessments/master/controls/get`)
    return await handleFetchResponse(response)
  },

  async downloadMasterControls() {
    const response = await R3sFetch(
      `/api/1.0/assessments/master/controls/download`
    )
    return await handleFetchResponse(response)
  },

  async updateMasterControl(info) {
    const response = await R3sFetch(
      `/api/1.0/assessments/master/controls/update`,
      {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(info),
      }
    )
    return await handleFetchResponse(response)
  },

  async updateControlMapping(info) {
    const response = await R3sFetch(
      `/api/1.0/assessments/master/controls/mapping/update`,
      {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(info),
      }
    )
    return await handleFetchResponse(response)
  },

  async getFormalFindingTemplate(projectId) {
    const response = await R3sFetch(
      `/api/1.0/assessments/formal/template/findings/get?id=${projectId || ''}`
    )
    return await handleFetchResponse(response)
  },

  async getFormalFindingGlobalTemplates() {
    const response = await R3sFetch(
      `/api/1.0/assessments/formal/template/findings/global/get`
    )
    return await handleFetchResponse(response)
  },

  async updateFormalFindingTemplate(info) {
    const response = await R3sFetch(
      `/api/1.0/assessments/formal/template/findings/update`,
      {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(info),
      }
    )
    return await handleFetchResponse(response)
  },

  async linkFindingTemplateToProject(info) {
    const response = await R3sFetch(
      `/api/1.0/assessments/formal/template/findings/project/add`,
      {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(info),
      }
    )
    return await handleFetchResponse(response)
  },

  async getRecentRequestsFromComments(
    projectId,
    search,
    includeInternal = false,
    page = 1,
    perPage = 10
  ) {
    const response = await R3sFetch(
      `/api/1.0/assessments/projects/comments/recent?projectId=${projectId}&search=${
        search || ''
      }&includeInternal=${includeInternal}&page=${page || ''}&perPage=${
        perPage || ''
      }`
    )
    return await handleFetchResponse(response)
  },

  async getRecentRequestsFromFiles(
    projectId,
    search,
    internal,
    page = 1,
    perPage = 10
  ) {
    const response = await R3sFetch(
      `/api/1.0/assessments/projects/files/recent?projectId=${projectId}&search=${
        search || ''
      }&page=${page || ''}&perPage=${perPage || ''}`
    )
    return await handleFetchResponse(response)
  },

  async findEvidenceOfParent(projectId, requestId, getParentsEvidence = false) {
    const response = await R3sFetch(
      `/api/1.0/assessments/evidence/find?projectId=${projectId}&requestId=${
        requestId || ''
      }&parent=${getParentsEvidence || ''}`
    )
    return await handleFetchResponse(response)
  },

  async getRequestMappings(requestId, projectId) {
    const response = await R3sFetch(
      `/api/1.0/assessments/request/map/suggest`,
      {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          projectId,
          requestId,
        }),
      }
    )
    return await handleFetchResponse(response)
  },

  async getRequestMappingsCount(projectIds) {
    const response = await R3sFetch(`/api/1.0/assessments/requests/map/count`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        ids: projectIds,
      }),
    })
    return await handleFetchResponse(response)
  },
  async getFrameworkKpis() {
    const response = await R3sFetch(`/api/1.0/assessments/frameworks/kpis`)
    return await handleFetchResponse(response)
  },

  async getFindingHistory(projectId, findingId) {
    const response = await R3sFetch(
      `/api/1.0/assessments/formal/finding/history/get?projectId=${projectId}&id=${
        findingId || ''
      }`
    )
    return await handleFetchResponse(response)
  },

  async getExecutiveSummary(projectId) {
    const response = await R3sFetch(
      `/api/1.0/assessments/executive/summary/get?id=${projectId || ''}`
    )
    return await handleFetchResponse(response)
  },

  async updateExecutiveSummary(projectId, summary) {
    const response = await R3sFetch(
      `/api/1.0/assessments/executive/summary/update`,
      {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ id: projectId, summary }),
      }
    )
    return await handleFetchResponse(response)
  },

  async getAssessmentAchievements(projectId) {
    const response = await R3sFetch(
      `/api/1.0/celebrations/assessment/milestones?projectId=${projectId || ''}`
    )
    return await handleFetchResponse(response)
  },

  async getAssessmentAchievementsPreview(projectId, isPreviewing) {
    const response = await R3sFetch(
      `/api/1.0/celebrations/assessment/milestones/mailer`,
      {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ projectId, isPreviewing }),
      }
    )
    return await handleFetchResponse(response)
  },

  async getControlsAndMyControlsMappings(projectId, page = 1, perPage = 10) {
    const response = await R3sFetch(
      `/api/1.0/assessments/controls/mycontrols/mappings?id=${projectId}&page=${page}&perPage=${perPage}`
    )
    return await handleFetchResponse(response)
  },

  async updateEvidenceRequestsFromMyControls({ projectId, controls }) {
    const response = await R3sFetch(
      `/api/1.0/assessments/controls/mycontrols/requests/update`,
      {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ projectId, controls }),
      }
    )
    return await handleFetchResponse(response)
  },

  async addMyControlsEvidenceFiles({
    projectId,
    evidenceId = null,
    includeAssignees = false,
    fileStatuses = [],
  }) {
    const response = await R3sFetch(
      `/api/1.0/assessments/requests/mycontrols/evidence/files`,
      {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          projectId,
          evidenceId,
          includeAssignees,
          fileStatuses,
        }),
      }
    )
    return await handleFetchResponse(response)
  },

  async updateEvidenceRequestEvidenceObject(requestId, evidenceId, action) {
    const response = await R3sFetch(
      `/api/1.0/assessments/requests/mycontrols/evidence/update`,
      {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ requestId, evidenceId, action }),
      }
    )
    return await handleFetchResponse(response)
  },

  async createEvidenceRequestEvidenceObject(requestIds) {
    const response = await R3sFetch(
      `/api/1.0/assessments/requests/mycontrols/evidence/create`,
      {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ requestIds }),
      }
    )
    return await handleFetchResponse(response)
  },

  async createControlControlGroup(controlIds) {
    const response = await R3sFetch(
      `/api/1.0/assessments/controls/mycontrols/control/group/create`,
      {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ controlIds }),
      }
    )
    return await handleFetchResponse(response)
  },

  async getControlsLinkedThroughControls(controlId, projectStatus = 'active') {
    const response = await R3sFetch(
      `/api/1.0/assessments/controls/linked/controls/get?controlId=${controlId}&projectStatus=${projectStatus}`
    )
    return await handleFetchResponse(response)
  },
}
