<script>
  import { mapState } from 'vuex'
  import BaseFilterComponent from '../BaseFilterComponent.vue'

  export default {
    extends: BaseFilterComponent,

    data() {
      return {
        name: 'Test Results',
      }
    },

    computed: {
      ...mapState({
        projectId: (state) => state.assessments.currentProject.id,
        options(state) {
          return state.assessmentProjectGapTypes(this.projectId)
        },
        selectedOptions(_, getters) {
          return this.options.filter((opt) =>
            getters
              .getDataByPath(this.savedFiltersStatePath + '.testResult')
              .includes(opt.value)
          )
        },
      }),
    },
  }
</script>
