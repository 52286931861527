<script>
  import { mapState } from 'vuex'
  import BaseFilterComponent from '../BaseFilterComponent.vue'
  import Strings from '../../../../../factories/StringHelpers'

  export default {
    extends: BaseFilterComponent,

    data() {
      return {
        name: 'Evidence',
      }
    },

    computed: {
      ...mapState({
        options(state) {
          const sortedEvidenceObjects = [
            ...state.mycontrols.evidence.data,
          ].sort((a, b) => a.name.localeCompare(b.name))
          return [{ name: 'No Mapped Evidence', id: 0 }]
            .concat(sortedEvidenceObjects || [])
            .map((e) => ({
              text: Strings.truncateString(e.name, 50),
              value: e.id,
            }))
        },
        selectedOptions(_, getters) {
          return this.options.filter((opt) =>
            getters
              .getDataByPath(this.savedFiltersStatePath + '.evidence')
              .includes(opt.value)
          )
        },
      }),
    },

    mounted() {
      this.$store.dispatch('getTeamEvidence', false)
    },
  }
</script>
