function updateCursor(ev, el) {
  if (ev.ctrlKey || ev.metaKey) {
    el.style.cursor = 'grab'
  } else {
    el.style.cursor = ''
  }
}
const vDragscroll = {
  install(Vue) {
    Vue.directive('dragscroll', {
      beforeMount: function (el, binding) {
        if (!el) return
        // Listen for key events to change the cursor
        document.addEventListener('keydown', (ev) => updateCursor(ev, el))
        document.addEventListener('keyup', (ev) => updateCursor(ev, el))

        el.onmousedown = function (ev) {
          if (!ev.ctrlKey && !ev.metaKey) return // Only activate if CTRL (or CMD) is held

          el.style.cursor = 'grabbing' // Change cursor to grabbing when dragging

          const disX = ev.clientX
          const disY = ev.clientY
          const originalScrollLeft = el.scrollLeft
          const originalScrollTop = el.scrollTop
          const originalScrollBehavior = el.style['scroll-behavior']
          const originalPointerEvents = el.style['pointer-events']

          el.style['scroll-behavior'] = 'auto'

          document.onmousemove = function (ev) {
            ev.preventDefault()
            const distanceX = ev.clientX - disX
            const distanceY = ev.clientY - disY
            el.scrollTo(
              originalScrollLeft - distanceX,
              originalScrollTop - distanceY
            )
            el.style['pointer-events'] = 'none'
          }

          document.onmouseup = function () {
            document.onmousemove = null
            document.onmouseup = null
            el.style['scroll-behavior'] = originalScrollBehavior
            el.style['pointer-events'] = originalPointerEvents
            el.style.cursor = 'grab' // Restore grab cursor after release
          }
        }

        // Remove event listeners when element is detached
        el.onmouseleave = function () {
          el.style.cursor = '' // Reset cursor when leaving the element
        }
      },
      unmounted: function (element) {
        document.body.removeEventListener('keydown', updateCursor)
        document.body.removeEventListener('keyup', updateCursor)
      },
    })
  },
}

export default vDragscroll
