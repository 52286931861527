<script>
  import { mapState } from 'vuex'
  import BaseFilterComponent from '../BaseFilterComponent.vue'

  export default {
    extends: BaseFilterComponent,

    data() {
      return {
        name: 'User',
      }
    },

    computed: {
      ...mapState({
        options(state) {
          return [{ name: 'Not Set', id: 'unassigned' }]
            .concat(state.team.teamAndInternalUsers || [])
            .map((u) => ({ text: u.name || u.username_email, value: u.id }))
        },
        selectedOptions(_, getters) {
          return this.options.filter((opt) =>
            getters
              .getDataByPath(this.savedFiltersStatePath + '.assignee')
              .includes(opt.value)
          )
        },
      }),
    },

    mounted() {
      this.$store.dispatch('getTeamAndInternalUsers', false)
    },
  }
</script>
