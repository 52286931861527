<script>
  import { mapState } from 'vuex'
  import BaseFilterComponent from '../BaseFilterComponent.vue'

  export default {
    extends: BaseFilterComponent,

    data() {
      return {
        name: 'Mapped Evidence',
        options: [{ text: 'No mapped evidence', value: true }],
      }
    },

    computed: {
      ...mapState({
        selectedOptions(_, getters) {
          return this.options.filter((opt) =>
            getters
              .getDataByPath(this.savedFiltersStatePath + '.noMappedEvidence')
              .includes(opt.value)
          )
        },
      }),
    },
  }
</script>
