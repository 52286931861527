<script>
  import { mapState } from 'vuex'
  import BaseFilterComponent from '../BaseFilterComponent.vue'

  export default {
    extends: BaseFilterComponent,

    data() {
      return {
        name: 'User',
        isLoadingFilter: 'user',
      }
    },

    computed: {
      ...mapState({
        options: (state) =>
          state.projects.allProjectManagementOwners.map((o) => ({
            text: o.name,
            value: o.id,
          })) || [],
        selectedOptions(_, getters) {
          return this.options.filter((opt) =>
            getters
              .getDataByPath(this.savedFiltersStatePath + '.user')
              .includes(opt.value)
          )
        },
      }),
    },

    mounted() {
      this.$store.dispatch('getAllProjectManagementOwners')
    },

    methods: {
      setFilter(key, value) {
        this.$emit('update:modelValue', { key, value })
      },
    },
  }
</script>
