<script>
  import { mapState } from 'vuex'
  import BaseFilterComponent from '../BaseFilterComponent.vue'

  export default {
    extends: BaseFilterComponent,

    data() {
      return {
        name: 'User Status',
        options: [
          { value: 'active', text: 'Active' },
          { value: 'disabled', text: 'Disabled' },
        ],
      }
    },

    computed: {
      ...mapState({
        selectedOptions(_, getters) {
          return this.options.filter((opt) =>
            getters
              .getDataByPath(this.savedFiltersStatePath + '.userStatus')
              .includes(opt.value)
          )
        },
      }),
    },
  }
</script>
