export default {
  addRelationship: {
    type: 'secondary',
    prefixIcon: 'add',
  },

  addUser: {
    type: 'tertiary',
    prefixIcon: 'addUser',
  },

  basic: {},

  cancel: {
    type: 'tertiary',
    text: 'Cancel',
  },

  clear: {
    type: 'tertiary',
    text: 'Clear',
    postfixIcon: 'remove',
    danger: true,
  },

  comment: {
    type: 'primary',
    text: 'Add Comment',
  },

  create: {
    type: 'primary',
    prefixIcon: 'add',
    text: 'Create',
    size: 'medium',
  },

  createDropdown: {
    type: 'primary',
    prefixIcon: 'add',
    text: 'Create New',
    postfixIcon: 'downCaret',
    size: 'medium',
  },

  delete: {
    type: 'primary',
    prefixIcon: 'delete',
    text: 'Delete',
    danger: true,
  },

  download: {
    type: 'primary',
    prefixIcon: 'download',
  },

  dropdown: {
    postfixIcon: 'downCaret',
    type: 'secondary',
  },

  link: {
    type: 'tertiary',
    prefixIcon: 'link',
    text: 'Link',
    size: 'medium',
  },

  preview: {
    type: 'secondary',
    prefixIcon: 'show',
    text: 'Preview',
  },

  removeRelationship: {
    type: 'secondary',
    prefixIcon: 'remove',
    danger: true,
  },

  save: {
    type: 'primary',
    text: 'Save',
  },

  sendMail: {
    type: 'primary',
    prefixIcon: 'send',
    text: 'Send',
  },

  upload: {
    type: 'secondary',
    prefixIcon: 'uploadImport',
  },

  // icon button presets
  icon: {
    type: 'secondary',
    shape: 'circle',
    size: 'medium',
  },

  closeIcon: {
    preset: 'icon',
    prefixIcon: 'close',
  },

  createIcon: {
    preset: 'icon',
    prefixIcon: 'add',
  },

  editIcon: {
    preset: 'icon',
    prefixIcon: 'edit',
  },

  deleteIcon: {
    preset: 'icon',
    prefixIcon: 'delete',
    danger: true,
  },

  deselectIcon: {
    preset: 'icon',
    prefixIcon: 'unselected',
    shape: 'sqaure',
  },

  downloadIcon: {
    type: 'primary',
    prefixIcon: 'download',
    size: 'medium',
  },

  editIcon: {
    type: 'tertiary',
    prefixIcon: 'edit',
    shape: 'circle',
    size: 'small',
  },

  historyIcon: {
    preset: 'icon',
    prefixIcon: 'versionHistory',
    shape: 'sqaure',
  },

  removeIcon: {
    type: 'tertiary',
    prefixIcon: 'remove',
    shape: 'circle',
    danger: true,
  },

  selectIcon: {
    preset: 'icon',
    prefixIcon: 'selected',
    shape: 'square',
  },

  sendMailIcon: {
    type: 'primary',
    prefixIcon: 'send',
    size: 'medium',
  },
}
