import DashboardApplicabilityFilter from './myControls/DashboardApplicabilityFilter.vue'
import DashboardDomainFilter from './myControls/DashboardDomainFilter.vue'
import DashboardFrameworkFilter from './myControls/DashboardFrameworkFilter.vue'
import DashboardOwnerFilter from './myControls/DashboardOwnerFilter.vue'
import DashboardRiskCategoryFilter from './riskRegister/DashboardRiskCategoryFilter.vue'
import DashboardProjectFilter from './projects/DashboardProjectFilter.vue'
import DashboardProjectOwnerFilter from './projects/DashboardProjectOwnerFilter.vue'
import DashboardProjectStatusFilter from './projects/DashboardProjectStatusFilter.vue'
import DashboardProjectTagsFilter from './projects/DashboardProjectTagsFilter.vue'
import DashboardRiskDecisionFilter from './riskRegister/DashboardRiskDecisionFilter.vue'
import DashboardRiskRegisterFilter from './riskRegister/DashboardRiskRegisterFilter.vue'
import DashboardRiskScopeFilter from './riskRegister/DashboardRiskScopeFilter.vue'
import DashboardRiskScoreFilter from './riskRegister/DashboardRiskScoreFilter.vue'
import DashboardRiskStatusFilter from './riskRegister/DashboardRiskStatusFilter.vue'
import DashboardRiskTagFilter from './riskRegister/DashboardRiskTagFilter.vue'
import DashboardRiskUserFilter from './riskRegister/DashboardRiskUserFilter.vue'
import DashboardSectionFilter from './projects/DashboardSectionFilter.vue'
import DashboardScopeFilter from './myControls/DashboardScopeFilter.vue'
import DashboardTagsFilter from './myControls/DashboardTagsFilter.vue'
import DashboardCriticalityFilter from './projects/DashboardCriticalityFilter.vue'

export default {
  DashboardApplicabilityFilter,
  DashboardDomainFilter,
  DashboardFrameworkFilter,
  DashboardOwnerFilter,
  DashboardRiskCategoryFilter,
  DashboardRiskDecisionFilter,
  DashboardRiskRegisterFilter,
  DashboardRiskScopeFilter,
  DashboardRiskScoreFilter,
  DashboardRiskStatusFilter,
  DashboardRiskTagFilter,
  DashboardRiskUserFilter,
  DashboardSectionFilter,
  DashboardScopeFilter,
  DashboardTagsFilter,
  DashboardProjectOwnerFilter,
  DashboardProjectStatusFilter,
  DashboardProjectTagsFilter,
  DashboardProjectFilter,
  DashboardApplicabilityFilter,
  DashboardCriticalityFilter,
}
