const vClickOutisde = {
  install(Vue) {
    Vue.directive('click-outside', {
      beforeMount: function (el, binding) {
        el.clickOutsideEvent = async (event) => {
          if (!(el === event.target || el.contains(event.target))) {
            if (binding.value && typeof binding.value === 'function') {
              binding.value(event)
            }
          }
        }
        document.body.addEventListener('mousedown', el.clickOutsideEvent)
      },
      unmounted: function (element) {
        document.body.removeEventListener(
          'mousedown',
          element.clickOutsideEvent
        )
      },
    })
  },
}

export default vClickOutisde
