export default {
  SET_ORG_USERS_FILTER(state, { key, val }) {
    state.settings.filters.orgUsers[key] = val
  },
  CLEAR_ORG_USERS_FILTER(state) {
    state.settings.filters.orgUsers.userRole = []
    state.settings.filters.orgUsers.userStatus = []
  },

  SET_ORG_USERS(state, paginatedResponse) {
    state.settings.orgUsers = paginatedResponse
  },
}
