<script>
  import { mapState } from 'vuex'
  import BaseFilterComponent from '../BaseFilterComponent.vue'

  export default {
    extends: BaseFilterComponent,

    data() {
      return {
        name: 'User',
      }
    },

    computed: {
      ...mapState({
        options(state, getters) {
          const currentTab = this.$route.params.tab || 'evidence'
          const users =
            currentTab === 'testing'
              ? state.team.teamAndInternalUsers
              : getters.assessmentUsersAndProjectTeam
          return [
            {
              text: 'Unassigned',
              value: 'UNASSIGNED',
            },
          ].concat(
            (users || []).map((u) => ({
              text: u.name || u.username_email,
              value: u.user_id || u.id,
            }))
          )
        },
        selectedOptions(_, getters) {
          return this.options.filter((opt) =>
            getters
              .getDataByPath(this.savedFiltersStatePath + '.assignedTo')
              .includes(opt.value)
          )
        },
      }),
    },

    mounted() {
      this.$store.dispatch('getTeamAndInternalUsers', false)
    },
  }
</script>
