import R3sFetch from './R3sFetch'
import { handleFetchResponse } from './ApiHelpers'

export default {
  async getVendors() {
    const response = await R3sFetch(`/api/1.0/vendormanagement/vendors/all`)
    return await handleFetchResponse(response)
  },

  async getVendor(id) {
    const response = await R3sFetch(
      `/api/1.0/vendormanagement/vendors/get?id=${id}`
    )
    return await handleFetchResponse(response)
  },

  async createUpdateVendor(vendor) {
    const response = await R3sFetch(
      `/api/1.0/vendormanagement/vendors/create`,
      {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ vendor }),
      }
    )
    return await handleFetchResponse(response)
  },

  async mergeVendors(masterVendorId, vendorsToMerge) {
    const response = await R3sFetch(`/api/1.0/vendormanagement/vendors/merge`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        vendorId: masterVendorId,
        vendors: vendorsToMerge,
      }),
    })
    return await handleFetchResponse(response)
  },

  async getVendorCategories() {
    const response = await R3sFetch(
      `/api/1.0/vendormanagement/vendors/categories/all`
    )
    return await handleFetchResponse(response)
  },

  async updateVendorCategory(info) {
    const response = await R3sFetch(
      `/api/1.0/vendormanagement/vendors/categories/update`,
      {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(info),
      }
    )
    return await handleFetchResponse(response)
  },

  async getVendorPolicies() {
    const response = await R3sFetch(
      `/api/1.0/vendormanagement/vendors/policies/all`
    )
    return await handleFetchResponse(response)
  },

  async updatePolicy(type, value, newText) {
    const response = await R3sFetch(
      `/api/1.0/vendormanagement/vendors/policy/update`,
      {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          policy_type: type,
          value: value,
          policy_text: newText,
        }),
      }
    )
    return await handleFetchResponse(response)
  },

  async getTeamVendors(filters = null, sorting = null, page = 1, perPage = 10) {
    const response = await R3sFetch(
      `/api/1.0/vendormanagement/vendors/team/all?filters=${encodeURIComponent(
        JSON.stringify(filters)
      )}&sorting=${encodeURIComponent(JSON.stringify(sorting))}&page=${
        page || 1
      }&perPage=${perPage || 20}`
    )
    return await handleFetchResponse(response)
  },

  async downloadTeamVendors() {
    const response = await R3sFetch(
      `/api/1.0/vendormanagement/vendors/team/download`
    )
    return await handleFetchResponse(response)
  },

  async getTeamVendor(id) {
    const response = await R3sFetch(
      `/api/1.0/vendormanagement/vendors/team/get?id=${id}`
    )
    return await handleFetchResponse(response)
  },

  async createUpdateTeamVendor(vendor) {
    const response = await R3sFetch(
      `/api/1.0/vendormanagement/vendors/team/create`,
      {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ operation: 'create', vendor }),
      }
    )
    return await handleFetchResponse(response)
  },

  async updateTeamVendor(vendorId, vendor) {
    const response = await R3sFetch(
      `/api/1.0/vendormanagement/vendors/team/create`,
      {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          operation: 'update',
          vendor: { vendor_id: vendorId, ...vendor },
        }),
      }
    )
    return await handleFetchResponse(response)
  },

  async deleteTeamVendor(id) {
    const response = await R3sFetch(
      `/api/1.0/vendormanagement/vendors/team/delete`,
      {
        method: 'DELETE',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ id }),
      }
    )
    return await handleFetchResponse(response)
  },

  async getReviews(filters = null, page = 1, perPage = 10) {
    const response = await R3sFetch(
      `/api/1.0/vendormanagement/reviews/all?filters=${encodeURIComponent(
        JSON.stringify(filters)
      )}&page=${page || 1}&perPage=${perPage || 20}`
    )
    return await handleFetchResponse(response)
  },

  async getReview(id) {
    const response = await R3sFetch(
      `/api/1.0/vendormanagement/reviews/get?id=${id}`
    )
    return await handleFetchResponse(response)
  },

  async createUpdateReview(review) {
    const response = await R3sFetch(`/api/1.0/vendormanagement/review/create`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ review }),
    })
    return await handleFetchResponse(response)
  },

  async deleteReview(id) {
    const response = await R3sFetch(`/api/1.0/vendormanagement/review/delete`, {
      method: 'DELETE',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ id }),
    })
    return await handleFetchResponse(response)
  },

  async getReviewStatuses() {
    const response = await R3sFetch(`/api/1.0/vendormanagement/review/statuses`)
    return await handleFetchResponse(response)
  },

  async getVendorStatuses() {
    const response = await R3sFetch(
      `/api/1.0/vendormanagement/vendors/statuses`
    )
    return await handleFetchResponse(response)
  },

  async getQuestionnaires(type = null, filters = null, page = 1, perPage = 10) {
    const response = await R3sFetch(
      `/api/1.0/vendormanagement/questionnaires/all?type=${
        type || ''
      }&filters=${encodeURIComponent(JSON.stringify(filters))}&page=${
        page || 1
      }&perPage=${perPage || 20}`
    )
    return await handleFetchResponse(response)
  },

  async getQuestionnaireTemplates(
    type = null,
    filters = null,
    page = 1,
    perPage = 10
  ) {
    const response = await R3sFetch(
      `/api/1.0/vendormanagement/questionnaires/templates/all?type=${
        type || ''
      }&filters=${encodeURIComponent(JSON.stringify(filters))}&page=${
        page || 1
      }&perPage=${perPage || 20}`
    )
    return await handleFetchResponse(response)
  },

  async createQuestionnaireTemplate(id) {
    const response = await R3sFetch(
      `/api/1.0/vendormanagement/questionnaires/templates/create`,
      {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ id }),
      }
    )
    return await handleFetchResponse(response)
  },

  async getQuestionnaire(id) {
    const response = await R3sFetch(
      `/api/1.0/vendormanagement/questionnaires/get?id=${id}`
    )
    return await handleFetchResponse(response)
  },

  async getQuestionnaireSectionsQuestions(id) {
    const response = await R3sFetch(
      `/api/1.0/vendormanagement/questionnaire/sections/questions/get?id=${id}`
    )
    return await handleFetchResponse(response)
  },

  async getQuestionnaireStatuses() {
    const response = await R3sFetch(
      `/api/1.0/vendormanagement/questionnaires/statuses`
    )
    return await handleFetchResponse(response)
  },

  async getQuestionnaireChoiceTemplates() {
    const response = await R3sFetch(
      `/api/1.0/vendormanagement/choice/templates/all`
    )
    return await handleFetchResponse(response)
  },

  async createUpdateChoiceTemplateGroup(info) {
    const response = await R3sFetch(
      `/api/1.0/vendormanagement/choice/templates/create`,
      {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(info),
      }
    )
    return await handleFetchResponse(response)
  },

  async createUpdateChoiceTemplateGroupChoices(info) {
    const response = await R3sFetch(
      `/api/1.0/vendormanagement/choice/templates/choices/update`,
      {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(info),
      }
    )
    return await handleFetchResponse(response)
  },

  async createUpdateQuestionnaire(questionnaire, overrideTeamId = null) {
    const response = await R3sFetch(
      `/api/1.0/vendormanagement/questionnaires/create`,
      {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ questionnaire, overrideTeamId }),
      }
    )
    return await handleFetchResponse(response)
  },

  async createUpdateQuestionnaireRetake(questionnaire, overrideTeamId = null) {
    const response = await R3sFetch(
      `/api/1.0/vendormanagement/questionnaires/retake`,
      {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ questionnaire, overrideTeamId }),
      }
    )
    return await handleFetchResponse(response)
  },

  async deleteQuestionnaire(id) {
    const response = await R3sFetch(
      `/api/1.0/vendormanagement/questionnaires/delete`,
      {
        method: 'DELETE',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ id }),
      }
    )
    return await handleFetchResponse(response)
  },

  async updateVendorOwners(vendorId, userIds) {
    const response = await R3sFetch(
      `/api/1.0/vendormanagement/vendors/team/owners/update`,
      {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ id: vendorId, userIds }),
      }
    )
    return await handleFetchResponse(response)
  },

  async removeVendorOwner(vendorId, userId) {
    const response = await R3sFetch(
      `/api/1.0/vendormanagement/vendors/team/owner/remove`,
      {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ vendorId, userId }),
      }
    )
    return await handleFetchResponse(response)
  },

  async updateQuestionnaireReviewers(questionnaireId, userIds) {
    const response = await R3sFetch(
      `/api/1.0/vendormanagement/questionnaires/reviewers/update`,
      {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ id: questionnaireId, userIds }),
      }
    )
    return await handleFetchResponse(response)
  },

  async removeQuestionnaireReviewer(questionnaireId, userId) {
    const response = await R3sFetch(
      `/api/1.0/vendormanagement/questionnaires/reviewers/remove`,
      {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ id: questionnaireId, userId }),
      }
    )
    return await handleFetchResponse(response)
  },

  async updateQuestionnaireSection(info) {
    const response = await R3sFetch(
      `/api/1.0/vendormanagement/questionnaires/section/update`,
      {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(info),
      }
    )
    return await handleFetchResponse(response)
  },

  async moveQuestionnaireQuestion(info) {
    const response = await R3sFetch(
      `/api/1.0/vendormanagement/questionnaires/question/move`,
      {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(info),
      }
    )
    return await handleFetchResponse(response)
  },

  async updateQuestionnaireQuestion(info) {
    const response = await R3sFetch(
      `/api/1.0/vendormanagement/questionnaires/question/update`,
      {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(info),
      }
    )
    return await handleFetchResponse(response)
  },

  async updateQuestionnaireChoice(info) {
    const response = await R3sFetch(
      `/api/1.0/vendormanagement/questionnaires/question/choice/update`,
      {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(info),
      }
    )
    return await handleFetchResponse(response)
  },

  async addUserToVendor(teamVendorId, userId) {
    const response = await R3sFetch(
      `/api/1.0/vendormanagement/vendor/user/add`,
      {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ teamVendorId, userId }),
      }
    )
    return await handleFetchResponse(response)
  },

  async removeUserFromVendor(teamVendorId, userId) {
    const response = await R3sFetch(
      `/api/1.0/vendormanagement/vendor/user/remove`,
      {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ teamVendorId, userId }),
      }
    )
    return await handleFetchResponse(response)
  },

  async addUserToQuestionnaire(questionnaireId, userId) {
    const response = await R3sFetch(
      `/api/1.0/vendormanagement/questionnaire/user/add`,
      {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ questionnaireId, userId }),
      }
    )
    return await handleFetchResponse(response)
  },

  async removeUserFromQuestionnaire(questionnaireId, userId) {
    const response = await R3sFetch(
      `/api/1.0/vendormanagement/questionnaire/user/remove`,
      {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ questionnaireId, userId }),
      }
    )
    return await handleFetchResponse(response)
  },

  async deployQuestionnaire(questionnaireId, message) {
    const response = await R3sFetch(
      `/api/1.0/vendormanagement/questionnaire/deploy`,
      {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ questionnaireId, message }),
      }
    )
    return await handleFetchResponse(response)
  },

  async remindVendorOfQuestionnaire(questionnaireId) {
    const response = await R3sFetch(
      `/api/1.0/vendormanagement/questionnaire/remind`,
      {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ questionnaireId }),
      }
    )
    return await handleFetchResponse(response)
  },

  async bulkUploadQuestions(questionnaireId, fileId) {
    const response = await R3sFetch(
      `/api/1.0/vendormanagement/questionnaire/bulk/import`,
      {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ questionnaireId, fileId }),
      }
    )
    return await handleFetchResponse(response)
  },

  async bulkDownloadQuestions(questionnaireId) {
    const response = await R3sFetch(
      `/api/1.0/vendormanagement/questionnaire/bulk/download`,
      {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ questionnaireId }),
      }
    )
    return await handleFetchResponse(response)
  },

  async bulkUploadVendors(fileId) {
    const response = await R3sFetch(
      `/api/1.0/vendormanagement/vendor/bulk/import`,
      {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ fileId }),
      }
    )
    return await handleFetchResponse(response)
  },

  async exportQuestionnaireFinalReport(info, signal = null) {
    const response = await R3sFetch(
      `/api/1.0/vendormanagement/questionnaire/submit/report/generate`,
      {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(info),
        signal,
      }
    )
    return await handleFetchResponse(response)
  },

  async getControlsFromQuestionnaire(questionnaireId) {
    const response = await R3sFetch(
      `/api/1.0/vendormanagement/questionnaire/controls/get?id=${questionnaireId}`
    )
    return await handleFetchResponse(response)
  },

  async getControlsFromQuestion(questionId) {
    const response = await R3sFetch(
      `/api/1.0/vendormanagement/questionnaire/question/controls/get?id=${questionId}`
    )
    return await handleFetchResponse(response)
  },

  async postControlQuestionMap(questionId, controls) {
    const response = await R3sFetch(
      `/api/1.0/vendormanagement/questionnaire/question/controls/post`,
      {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ questionId: questionId, controls: controls }),
      }
    )
    return await handleFetchResponse(response)
  },
}
