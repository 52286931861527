<script>
  import { mapState } from 'vuex'
  import BaseFilterComponent from '../BaseFilterComponent.vue'

  export default {
    extends: BaseFilterComponent,

    data() {
      return {
        name: 'Include Children',
      }
    },

    computed: {
      ...mapState({
        options(_, getters) {
          return getters.getCurrentProjectChildren.map((child) => ({
            text: child.name,
            value: child.id,
          }))
        },
        selectedOptions(_, getters) {
          return this.options.filter((opt) =>
            getters
              .getDataByPath(this.savedFiltersStatePath + '.includedChildren')
              .includes(opt.value)
          )
        },
      }),
    },
  }
</script>
