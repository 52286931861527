<script>
  import { mapState } from 'vuex'
  import BaseFilterComponent from '../BaseFilterComponent.vue'

  export default {
    extends: BaseFilterComponent,

    data() {
      return {
        name: 'Criticality',
      }
    },

    computed: {
      ...mapState({
        options: (state) =>
          state.projects.criticalities.map((c) => ({
            text: c.text,
            value: c.val,
          })),
        selectedOptions(_, getters) {
          return this.options.filter((opt) =>
            getters
              .getDataByPath(this.savedFiltersStatePath + '.criticality')
              .includes(opt.value)
          )
        },
      }),
    },
  }
</script>
