<script>
  import { mapState } from 'vuex'
  import BaseFilterComponent from '../BaseFilterComponent.vue'

  export default {
    extends: BaseFilterComponent,

    data() {
      return {
        name: 'Due Date',
      }
    },

    computed: {
      options() {
        return [
          {
            text: 'Due Before Today',
            value: 'due_before_today',
          },
          {
            text: 'Due Today',
            value: 'due_today',
          },
          {
            text: 'Due After Today',
            value: 'due_after_today',
          },
          {
            text: 'Due This Week',
            value: 'due_this_week',
          },
          {
            text: 'Due Next Week',
            value: 'due_next_week',
          },
          {
            text: 'Due Next 14 Days',
            value: 'due_next_fourteen_days',
          },
        ]
      },
      ...mapState({
        selectedOptions(_, getters) {
          return this.options.filter((opt) =>
            getters
              .getDataByPath(this.savedFiltersStatePath + '.dueDate')
              .includes(opt.value)
          )
        },
      }),
    },
  }
</script>
