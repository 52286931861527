import ApiRiskRegister from '../../factories/ApiRiskRegister'

export default {
  async initRiskRegister({ dispatch, state }, reset = state.globalReset) {
    await Promise.all([
      dispatch('getRiskRegisterPolicies', reset),
      dispatch('getRiskRegisterCategories', reset),
      dispatch('getRiskRegisterStatuses'),
      dispatch('getRegisters', { reset }),
      dispatch('getRiskRegisterTags', reset),
      dispatch('getAllRiskRegisterOwners'),
      dispatch('getRiskRegisterScopeGroups'),
    ])
  },

  async getRiskRegisterPolicies({ commit, state } /* , reset = false */) {
    // if (state.riskregister.impactScores.length > 0 && !reset) return
    if (!state.riskregister.currentRegister.id) return

    const policies = await ApiRiskRegister.getPolicies(
      state.riskregister.currentRegister.id
    )
    commit('SET_RISK_REGISTER_POLICIES', policies)
  },

  async getRiskRegisterCategories(
    { commit, state },
    reset = state.globalReset
  ) {
    const registerId = state.riskregister.currentRegister.id
    if (
      registerId &&
      (state.riskregister.categories[registerId] || []).length > 0 &&
      !reset
    ) {
      return
    }

    const { categories } = await ApiRiskRegister.getCategories(registerId)
    commit('SET_RISK_REGISTER_CATEGORIES', categories)
  },

  async getRegisters(
    { commit, state },
    { status = 'active', reset = state.globalReset }
  ) {
    if (state.riskregister.riskRegisters.length > 0 && !reset) return

    const { registers } = await ApiRiskRegister.getAllRegisters(status)
    commit('SET_RISK_REGISTERS', registers)
  },

  async getRiskRegisterStatuses({ commit, state }) {
    if (state.riskregister.statuses.length > 0) return //Statuses and treatment_descisions are not currently updated in the DB, so 'RESET' is never valid.

    const { statuses, decisions } =
      await ApiRiskRegister.getStatusesAndDecisions()
    commit('SET_RISK_REGISTER_STATUSES', statuses)
    commit('SET_RISK_REGISTER_TREATMENT_DECISIONS', decisions)
  },

  async riskRegisterItemChangePage({ commit, dispatch }, newPage) {
    commit('SET_RISK_REGISTER_ITEMS_PAGE', newPage)
    await dispatch('getRiskRegisterItems', { reset: true })
  },

  async riskRegisterItemChangePerPage({ commit, dispatch }, newPerPage) {
    commit('SET_RISK_REGISTER_ITEMS_PAGE', 1)
    commit('SET_RISK_REGISTER_ITEMS_PER_PAGE', newPerPage)
    await dispatch('getRiskRegisterItems', { reset: true })
  },

  async riskRegisterItemFrontendChangePage(
    { commit, dispatch, state },
    { newPage, isGlobalTemplate }
  ) {
    const globalReset = state.globalReset
    const riskRegisterId = isGlobalTemplate ? 'global' : null
    await dispatch('getRiskRegisterItems', { globalReset, riskRegisterId })
    commit('SET_RISK_REGISTER_FRONTEND_PAGINATION_OBJ', {
      currentPage: newPage,
    })
  },

  async riskRegisterItemFrontendChangePerPage(
    { commit, dispatch, state },
    { newPerPage, isGlobalTemplate }
  ) {
    const globalReset = state.globalReset
    const riskRegisterId = isGlobalTemplate ? 'global' : null
    newPerPage = newPerPage ?? state.riskregister.frontendPaginatedData.perPage
    await dispatch('getRiskRegisterItems', { globalReset, riskRegisterId })
    commit('SET_RISK_REGISTER_FRONTEND_PAGINATION_OBJ', {
      currentPage: 1,
      perPage: newPerPage,
    })
  },

  async getRiskRegisterItems(
    { commit, state },
    { reset = state.globalReset, riskRegisterId = null }
  ) {
    try {
      if (state.riskregister.items.length > 0 && !reset) return

      const { items } = await ApiRiskRegister.getAllItems(
        riskRegisterId ? riskRegisterId : state.riskregister.currentRegister.id,
        state.riskregister.itemFilters,
        state.riskregister.items.currentPage,
        state.riskregister.items.perPage,
        state.riskregister.sorting
      )
      commit('SET_RISK_REGISTER_ITEMS', items)
      commit('SET_RISK_REGISTER_ERROR', null)
    } catch (err) {
      commit('SET_RISK_REGISTER_ERROR', err.message)
      throw err
    }
  },

  async getAllRiskRegisterOwners({ commit }) {
    const owners = await ApiRiskRegister.getAllRiskRegisterOwners()
    commit('SET_RISK_REGISTER_ALL_OWNERS', owners)
  },

  async getRiskRegisterDashboardData({ dispatch, state }) {
    const countTypes = state.riskregister.dashboardCountCardTypes

    await Promise.all([
      ...countTypes.map((countType) =>
        dispatch('getRiskRegisterDashboardRiskCountsData', { countType })
      ),
      // by default, group by category
      dispatch('getRiskRegisterDashboardByXCounts', { groupBy: 'category' }),
      dispatch('getRiskRegisterDashboardOwnerCounts'),
      dispatch('getRRDashboardTreatmentDecision', { reset: true }),
      dispatch('getRRDashboardRiskAging', { reset: true }),
      dispatch('getRRDashboardTopRisks', { reset: true }),
      dispatch('getRRDashboardRiskHistory', { reset: true }),
    ])
  },

  async getRiskRegisterDashboardRiskCountsData(
    { state, commit },
    { countType }
  ) {
    function toggleLoading(com, key, val) {
      com('SET_RISK_REGISTER_DASHBOARD_LOADING', {
        key,
        val,
      })
    }
    function addData(com, key, val) {
      com('SET_RISK_REGISTER_DASHBOARD_DATA', {
        key,
        val,
      })
    }

    if (countType == 'total-risks') {
      toggleLoading(commit, 'risksTotalCount', true)

      const { count } = await ApiRiskRegister.getDashboardRiskCount(
        state.riskregister.dashboardFilters
      )
      addData(commit, 'risksTotalCount', count)

      toggleLoading(commit, 'risksTotalCount', false)
    } else if (countType == 'risks-opened') {
      toggleLoading(commit, 'risksOpenedCount', true)

      const riskOpenedFilters = {
        ...state.riskregister.dashboardFilters,
        dateRange: state.riskregister.dashboardFilters.risksOpenedDateRange,
        limitByDateType: 'open',
      }
      const { count } = await ApiRiskRegister.getDashboardRiskCount(
        riskOpenedFilters
      )
      addData(commit, 'risksOpenedCount', count)

      toggleLoading(commit, 'risksOpenedCount', false)
    } else if (countType == 'risks-marked-complete') {
      toggleLoading(commit, 'risksMarkedCompleteCount', true)

      const riskCompleteFilters = {
        ...state.riskregister.dashboardFilters,
        dateRange:
          state.riskregister.dashboardFilters.risksMarkedCompleteDateRange,
        limitByDateType: 'complete',
      }
      const { count } = await ApiRiskRegister.getDashboardRiskCount(
        riskCompleteFilters
      )
      addData(commit, 'risksMarkedCompleteCount', count)

      toggleLoading(commit, 'risksMarkedCompleteCount', false)
    }
  },

  async getRiskRegisterDashboardOwnerCounts({ state, commit }) {
    commit('SET_RISK_REGISTER_DASHBOARD_LOADING', {
      key: 'riskByOwner',
      val: true,
    })

    const counts = await ApiRiskRegister.getDashboardOwnerCounts(
      state.riskregister.dashboardFilters
    )
    commit('SET_RISK_REGISTER_DASHBOARD_DATA', {
      key: 'ownerCounts',
      val: counts,
    })

    commit('SET_RISK_REGISTER_DASHBOARD_LOADING', {
      key: 'riskByOwner',
      val: false,
    })
  },

  async getRiskRegisterDashboardByXCounts({ state, commit }, { groupBy }) {
    commit('SET_RISK_REGISTER_DASHBOARD_LOADING', {
      key: 'riskByX',
      val: true,
    })

    try {
      const counts = await ApiRiskRegister.getDashboardByXCounts(
        groupBy,
        state.riskregister.dashboardFilters
      )
      commit('SET_RISK_REGISTER_DASHBOARD_DATA', {
        key: 'byXCounts',
        val: counts,
      })
    } finally {
      commit('SET_RISK_REGISTER_DASHBOARD_LOADING', {
        key: 'riskByX',
        val: false,
      })
    }
  },

  setRiskByXGroupBy({ commit }, groupBy) {
    commit('SET_RISK_REGISTER_DASHBOARD_BY_X_GROUP_BY', groupBy)
  },

  async getRRDashboardTreatmentDecision(
    { commit, state },
    { reset = state.globalReset }
  ) {
    if (
      state.riskregister.dashboardData.riskCountsByTreatmentDecision.length >
        0 &&
      !reset
    )
      return

    commit('SET_RISK_REGISTER_DASHBOARD_LOADING', {
      key: 'riskCountsByTreatmentDecision',
      val: true,
    })

    const { riskCountsByTreatmentDecision } =
      await ApiRiskRegister.getDashboardRisksByTreatmentDecision(
        state.riskregister.dashboardFilters
      )

    const counts = (riskCountsByTreatmentDecision || []).map((item) => ({
      key: item.value,
      value: item.count,
      variant: item.variant,
    }))

    commit('SET_RISK_REGISTER_DASHBOARD_DATA', {
      key: 'riskCountsByTreatmentDecision',
      val: counts,
    })
    commit('SET_RISK_REGISTER_DASHBOARD_LOADING', {
      key: 'riskCountsByTreatmentDecision',
      val: false,
    })
  },

  async getRRDashboardRiskAging(
    { commit, state },
    { reset = state.globalReset }
  ) {
    if (state.riskregister.dashboardData.riskAging.length > 0 && !reset) return

    commit('SET_RISK_REGISTER_DASHBOARD_LOADING', {
      key: 'riskAging',
      val: true,
    })

    const { riskAging } = await ApiRiskRegister.getDashboardRiskAging(
      state.riskregister.dashboardFilters
    )
    commit('SET_RISK_REGISTER_DASHBOARD_DATA', {
      key: 'riskAging',
      val: riskAging,
    })
    commit('SET_RISK_REGISTER_DASHBOARD_LOADING', {
      key: 'riskAging',
      val: false,
    })
  },

  async getRRDashboardTopRisks(
    { commit, state },
    { reset = state.globalReset }
  ) {
    if (state.riskregister.dashboardData.topRisks.length > 0 && !reset) return

    commit('SET_RISK_REGISTER_DASHBOARD_LOADING', {
      key: 'topRisks',
      val: true,
    })

    const { topRisks } = await ApiRiskRegister.getDashboardTopRisks(
      state.riskregister.dashboardFilters
    )
    commit('SET_RISK_REGISTER_DASHBOARD_DATA', {
      key: 'topRisks',
      val: topRisks,
    })
    commit('SET_RISK_REGISTER_DASHBOARD_LOADING', {
      key: 'topRisks',
      val: false,
    })
  },

  async getRRDashboardRiskHistory(
    { commit, state },
    { reset = state.globalReset }
  ) {
    if (state.riskregister.dashboardData.riskHistory.length > 0 && !reset)
      return

    commit('SET_RISK_REGISTER_DASHBOARD_LOADING', {
      key: 'riskHistory',
      val: true,
    })

    const { riskHistory } = await ApiRiskRegister.getDashboardRiskHistory(
      state.riskregister.dashboardFilters
    )

    commit('SET_RISK_REGISTER_DASHBOARD_DATA', {
      key: 'riskHistory',
      val: riskHistory,
    })
    commit('SET_RISK_REGISTER_DASHBOARD_LOADING', {
      key: 'riskHistory',
      val: false,
    })
  },

  setRiskRegisterDashboardDateRange({ commit }, { countType, dateRange }) {
    switch (countType) {
      case 'risks-opened':
        commit('SET_RISK_REGISTER_DASHBOARD_FILTER', {
          key: 'risksOpenedDateRange',
          val: dateRange,
        })
        break
      case 'risks-marked-complete':
        commit('SET_RISK_REGISTER_DASHBOARD_FILTER', {
          key: 'risksMarkedCompleteDateRange',
          val: dateRange,
        })
        break
      case 'risk-history':
        commit('SET_RISK_REGISTER_DASHBOARD_FILTER', {
          key: 'riskHistoryDateRange',
          val: dateRange,
        })
        break
    }
  },

  async getRiskRegisterTags({ commit, state }, reset = state.globalReset) {
    if (state.riskregister.allTagsInRegister.length > 0 && !reset) return

    const { tags } = await ApiRiskRegister.getAllTags()
    commit('SET_RISK_REGISTER_TAGS', tags)
  },

  async getRiskRegisterScopeGroups(
    { commit, state },
    reset = state.globalReset
  ) {
    if (state.riskregister.allScopeGroupsInRegister.length > 0 && !reset) return

    const scopeGroups = await ApiRiskRegister.getAllScopeGroups()
    commit('SET_RISK_REGISTER_SCOPE_GROUPS', scopeGroups)
  },

  async getRiskRegisterItem(
    { commit, state },
    itemId = state.riskregister.currentItem.record.id
  ) {
    try {
      const {
        item,
        metricHistory,
        comments,
        files,
        owners,
        tags,
        changeLogs,
        pmTimeline,
        jiraMappings,
        calendarEvents,
        teamVendors,
        inventoryItems,
        controls,
        formalFindings,
        projectTasks,
        myControls,
        scopeGroups,
      } = await ApiRiskRegister.getItem(itemId)

      commit('SET_RISK_REGISTER_ITEM', item)
      commit('SET_RISK_REGISTER_ITEM_METRIC_HISTORY', metricHistory)
      commit('SET_RISK_REGISTER_ITEM_COMMENTS', comments)
      commit('SET_RISK_REGISTER_ITEM_FILES', files)
      commit('SET_RISK_REGISTER_ITEM_OWNERS', owners)
      commit('SET_RISK_REGISTER_ITEM_TAGS', tags)
      commit('SET_RISK_REGISTER_ITEM_CHANGE_LOGS', changeLogs)
      commit('SET_RISK_REGISTER_ITEM_PMTIMELINE', pmTimeline)
      commit('SET_RISK_REGISTER_ITEM_JIRA_MAPPINGS', jiraMappings)
      commit('SET_RISK_REGISTER_ITEM_EVENTS', calendarEvents)
      commit('SET_RISK_REGISTER_ITEM_VENDORS', teamVendors)
      commit('SET_RISK_REGISTER_ITEM_INVENTORY_ITEMS', inventoryItems)
      commit('SET_RISK_REGISTER_ITEM_CONTROLS', controls)
      commit('SET_RISK_REGISTER_ITEM_FORMAL_FINDINGS', formalFindings)
      commit('SET_RISK_REGISTER_ITEM_PROJECT_TASKS', projectTasks)
      commit('SET_RISK_REGISTER_ITEM_MY_CONTROLS', myControls)
      commit('SET_RISK_REGISTER_ITEM_SCOPE_GROUPS', scopeGroups)
      commit('SET_RISK_REGISTER_ERROR', null)
    } catch (err) {
      commit('SET_RISK_REGISTER_ERROR', err.message)
      throw err
    }
  },

  clearRiskRegisterItem({ commit }) {
    commit('SET_RISK_REGISTER_ITEM', {})
    commit('SET_RISK_REGISTER_ITEM_METRIC_HISTORY', [])
    commit('SET_RISK_REGISTER_ITEM_COMMENTS', [])
    commit('SET_RISK_REGISTER_ITEM_FILES', [])
    commit('SET_RISK_REGISTER_ITEM_OWNERS', [])
    commit('SET_RISK_REGISTER_ITEM_TAGS', [])
    commit('SET_RISK_REGISTER_ITEM_CHANGE_LOGS', [])
    commit('SET_RISK_REGISTER_ITEM_PMTIMELINE', [])
    commit('SET_RISK_REGISTER_ITEM_JIRA_MAPPINGS', [])
    commit('SET_RISK_REGISTER_ITEM_EVENTS', [])
    commit('SET_RISK_REGISTER_ITEM_VENDORS', [])
    commit('SET_RISK_REGISTER_ITEM_INVENTORY_ITEMS', [])
    commit('SET_RISK_REGISTER_ITEM_CONTROLS', [])
    commit('SET_RISK_REGISTER_ITEM_FORMAL_FINDINGS', [])
    commit('SET_RISK_REGISTER_ITEM_PROJECT_TASKS', [])
    commit('SET_RISK_REGISTER_ERROR', null)
  },

  async getRiskRegisterRecord({ commit }, id) {
    try {
      const { assessmentMappings, register } =
        await ApiRiskRegister.getRegister(id)
      commit('SET_RISK_REGISTER', register)
      commit('SET_RISK_REGISTER_ASSESSMENT_MAPPINGS', assessmentMappings)
      commit('SET_RISK_REGISTER_ERROR', null)
    } catch (err) {
      commit('SET_RISK_REGISTER_ERROR', err.message)
      throw err
    }
  },

  async clearRiskRegisterFilters({ commit }) {
    commit('CLEAR_RISK_REGISTER_ITEM_FILTERS')
  },

  async setRiskRegisterFilter({ commit }, { key, val }) {
    commit('SET_RISK_REGISTER_ITEM_FILTER', { key, val })
  },

  async setRiskRegisterFilterAndGetItems({ commit, dispatch }, { key, val }) {
    commit('SET_RISK_REGISTER_ITEM_FILTER', { key, val })
    await dispatch('getRiskRegisterItems', { reset: true })
  },

  async riskRegisterItemOrderingUpdate(
    { dispatch },
    { registerId, itemIds, startIndex }
  ) {
    await ApiRiskRegister.updateItemOrdering(registerId, itemIds, startIndex)
    await dispatch('getRiskRegisterItems', { reset: true })
  },

  async setRiskRegisterSorting({ commit, dispatch }, { key, val }) {
    commit('SET_RISK_REGISTER_SORTING', { key, val })
    await dispatch('getRiskRegisterItems', { reset: true })
  },

  async getRiskRegisterRecentActivity({ commit, state }) {
    const registerId = state.riskregister.currentRegister.id
    if (!registerId) return

    const page = state.riskregister.recentActivity.page
    const perPage = state.riskregister.recentActivity.perPage

    const filters = state.riskregister.recentActivityFilters
    const { info } = await ApiRiskRegister.getRecentActivity(
      registerId,
      filters,
      page,
      perPage
    )
    commit('SET_RISK_REGISTER_RECENT_ACTIVITY', info)
  },

  async setRiskRegisterRecentActivityFilter(
    { commit, dispatch },
    { key, val }
  ) {
    commit('SET_RISK_REGISTER_RECENT_ACTIVITY_FILTER', { key, val })
    commit('SET_RISK_REGISTER_RECENT_ACTIVITY_PAGE', 1)
    await dispatch('getRiskRegisterRecentActivity', { reset: true })
  },

  async clearRiskRegisterRecentActivityFilters({ commit, dispatch }) {
    commit('CLEAR_RISK_REGISTER_RECENT_ACTIVITY_FILTERS')
    commit('SET_RISK_REGISTER_RECENT_ACTIVITY_PAGE', 1)
    await dispatch('getRiskRegisterRecentActivity', { reset: true })
  },

  async riskRegisterRecentActivityChangePage({ commit, dispatch }, newPage) {
    commit('SET_RISK_REGISTER_RECENT_ACTIVITY_PAGE', newPage)
    await dispatch('getRiskRegisterRecentActivity', { reset: true })
  },

  async riskRegisterRecentActivityChangePerPage(
    { commit, dispatch },
    newPerPage
  ) {
    commit('SET_RISK_REGISTER_RECENT_ACTIVITY_PAGE', 1)
    commit('SET_RISK_REGISTER_RECENT_ACTIVITY_PER_PAGE', newPerPage)
    await dispatch('getRiskRegisterRecentActivity', { reset: true })
  },
}
