<script>
  import { mapState } from 'vuex'
  import BaseFilterComponent from '../BaseFilterComponent.vue'

  export default {
    extends: BaseFilterComponent,

    data() {
      return {
        name: 'Treatment Decision',
      }
    },

    computed: {
      ...mapState({
        options: (state) => state.riskregister.decisions,
        selectedOptions(_, getters) {
          return this.options.filter((opt) =>
            getters
              .getDataByPath(this.savedFiltersStatePath + '.decision')
              .includes(opt.value)
          )
        },
      }),
    },

    mounted() {
      this.$store.dispatch('getRiskRegisterStatuses')
    },
  }
</script>
