export default {
  projects: {
    error: null,
    statuses: [],
    allProjectManagementGroups: [],
    allProjectManagementOwners: [],
    allProjectManagementTags: [],
    allProjectManagementScopeGroups: [],

    allTagsInProject: [],
    criticalities: [
      { val: 'extreme', text: 'Extreme', variant: 'danger' },
      { val: 'high', text: 'High', variant: 'warning' },
      { val: 'medium', text: 'Medium', variant: 'info' },
      { val: 'low', text: 'Low', variant: 'minimal' },
      { val: 'informational', text: 'Informational', variant: 'primary' },
      { val: null, text: 'Not Set', variant: 'gray' },
    ],
    taskTypes: [
      { name: 'Tasks', id: 'tasks' },
      { name: 'Subtasks', id: 'subtasks' },
    ],
    projects: [],
    currentProject: {},
    currentTask: {
      record: {},
      comments: [],
      files: [],
      collaborators: [],
      owners: [],
      tags: [],
      changelog: [],
      subtasks: [],
      riskItems: [],
      controls: [],
      formalFindings: [],
      penTestFindings: [],
      myControls: [],
      evidence: [],
      uploadUsers: [],
      scopeGroups: [],
    },
    taskSorting: {},
    taskSearch: null,
    taskFilters: {
      group: [],
      criticality: [],
      status: [],
      tag: [],
      taskTypes: [],
      scope: [],
      user: [],
      // assessment: [],
      dates: [],
      project: [],
    },
    dashboardFilters: {
      criticality: [],
      group: [],
      project: [],
      status: [],
      tag: [],
      scope: [],
      user: [],
      // "started", "completed", or null for task count cards
      limitByDateType: null,
      tasksOpenedDateRange: '3M',
      tasksMarkedCompleteDateRange: '3M',
      taskHistoryDateRange: '3M',
    },
    recentActivityFilters: {
      owner: [],
      createdDate: [],
      search: null,
    },
    dashboardLoading: {
      tasksTotalCount: false,
      tasksOpenedCount: false,
      tasksMarkedCompleteCount: false,

      tasksByOwner: false,

      taskCountsByCriticality: false,
      taskAging: false,

      tasksByX: false,
      tasksBehindSchedule: false,

      taskHistory: false,
    },
    dashboardData: {
      tasksTotalCount: 0,
      tasksOpenedCount: 0,
      tasksMarkedCompleteCount: 0,

      ownerCounts: [],

      taskCountsByCriticality: [],
      taskAging: [],

      byXCounts: [],
      tasksBehindSchedule: [],

      taskHistory: [],
    },
    dashboardDateRangeOptions: [
      { text: '30 Days', value: '1M' },
      { text: '60 Days', value: '2M' },
      { text: '90 Days', value: '3M' },
      { text: '6 Months', value: '6M' },
      { text: 'This Quarter', value: '1Q' },
      // currently the filter has no start date, so "Last Quarter" isn't doable yet.
      // { text: 'Last Quarter', value: '2Q' },
      { text: 'Year to Date', value: 'ytd' },
    ],
    dashboardCountCardTypes: [
      'total-tasks',
      'tasks-opened',
      'tasks-marked-complete',
    ],
    dashboardTaskByXGroupBy: { text: 'Status', value: 'status' },
    dashboardTaskByXCountType: 'criticality',
    groups: [],
    tasks: {
      currentPage: 1,
      data: [],
      numberPages: 1,
      perPage: 1e5,
      totalCount: 0,
    },
    allTasks: [],
    recentActivity: {
      currentPage: 1,
      data: [],
      numberPages: 1,
      perPage: 25,
      totalCount: 0,
    },
    frontendPaginatedData: {
      currentPage: 1,
      perPage: 5,
    },
    availablePeriods: [
      { value: 'D', text: 'Day(s)' },
      { value: 'W', text: 'Week(s)' },
      { value: 'M', text: 'Month(s)' },
      { value: 'Q', text: 'Quarter(s)' },
      { value: 'Y', text: 'Year(s)' },
    ],
    getRowNumber(index) {
      return (
        this.frontendPaginatedData.perPage *
          (this.frontendPaginatedData.currentPage - 1) +
        (index + 1)
      )
    },
    getCriticalityVariant(criticality) {
      return this.criticalities.find((c) => c.val == criticality).variant
    },
    projectsCriticalityBubbleInfo(tasks) {
      return this.criticalities.map((c) => {
        return {
          key: c.val || 'not_set',
          value: tasks.filter((task) => task.criticality === c.val).length,
          subvalue: `${tasks
            .filter((task) => task.criticality === c.val)
            .reduce((t, i) => t + i.hours_to_complete, 0)} hours`,
          variant: c.variant,
        }
      })
    },
  },
}
