<script>
  import { mapState } from 'vuex'
  import BaseFilterComponent from '../BaseFilterComponent.vue'
  import Strings from '../../../../../factories/StringHelpers'

  export default {
    extends: BaseFilterComponent,

    data() {
      return {
        name: 'Domain',
      }
    },

    computed: {
      ...mapState({
        options(state) {
          return [
            {
              text: 'Show Deleted Domains',
              value: 'deleted',
              variant: 'primary',
            },
            {
              text: 'Include Empty Domains',
              value: 'empty',
              variant: 'primary',
            },
            ...state.mycontrols.controlsDomains.map((domain) => ({
              text: Strings.truncateString(domain.name, 50),
              value: domain.id,
            })),
          ]
        },
        selectedOptions(_, getters) {
          return this.options.filter((opt) =>
            getters
              .getDataByPath(this.savedFiltersStatePath + '.domain')
              .includes(opt.value)
          )
        },
      }),
    },

    mounted() {
      this.$store.dispatch('getTeamControlsDomains', { reset: false })
    },
  }
</script>
