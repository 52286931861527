<script>
  import { mapState } from 'vuex'
  import BaseFilterComponent from '../BaseFilterComponent.vue'

  export default {
    extends: BaseFilterComponent,

    data() {
      return {
        name: 'Expiration',
      }
    },

    computed: {
      ...mapState({
        options(state) {
          return state.mycontrols.evidenceExpirationOptions
        },
        selectedOptions(_, getters) {
          return this.options.filter((opt) =>
            getters
              .getDataByPath(this.savedFiltersStatePath + '.expiration')
              .includes(opt.value)
          )
        },
      }),
    },
  }
</script>
