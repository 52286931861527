<script>
  import { mapState } from 'vuex'
  import BaseFilterComponent from '../BaseFilterComponent.vue'

  export default {
    extends: BaseFilterComponent,

    data() {
      return {
        name: 'Category',
      }
    },

    computed: {
      ...mapState({
        options: function (state) {
          const allCats = state.riskregister.categories
          if (
            state.riskregister.currentRegister.id == 0 ||
            state.riskregister.currentRegister.id == undefined
          ) {
            return Object.values(allCats)
              .flat(Infinity)
              .filter((cat) => {
                const registerName =
                  state.riskregister.riskRegisters.find(
                    (r) => r.id == cat.risk_register_id
                  ) || {}

                if (!registerName.name) {
                  return false
                }
                return cat.risk_register_id
              })
              .map((cat) => {
                const registerName = (
                  state.riskregister.riskRegisters.find(
                    (r) => r.id == cat.risk_register_id
                  ) || {}
                ).name

                return {
                  text: `${cat.name} (${registerName})`,
                  value: cat.id,
                }
              })
              .sort((a, b) => a.text.localeCompare(b.text))
          }

          const sortedCats = allCats[
            state.riskregister.currentRegister.id || 0
          ]?.sort((a, b) => a.name.localeCompare(b.name))
          return (sortedCats || []).map((cat) => {
            return {
              text: cat.name,
              value: cat.id,
            }
          })
        },
        selectedOptions(_, getters) {
          return this.options.filter((opt) =>
            getters
              .getDataByPath(this.savedFiltersStatePath + '.category')
              .includes(opt.value)
          )
        },
      }),
    },

    mounted() {
      this.$store.dispatch('getRiskRegisterCategories')
    },
  }
</script>
