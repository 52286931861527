import ApiProjects from '../../factories/ApiProjects'

export default {
  async initProject({ dispatch, state }, reset = state.globalReset) {
    await Promise.all([
      // dispatch('getProjectPolicies', reset),
      dispatch('getProjectStatuses', reset),
      dispatch('getAllScopes', reset),
    ])
  },

  async getProjects(
    { commit, state },
    {
      isDeleted = false,
      reset = state.globalReset,
      showArchivedProjects = false,
    }
  ) {
    if (state.projects.projects.length > 0 && !reset) return

    const { projects } = await ApiProjects.getAllProjects(
      isDeleted,
      showArchivedProjects
    )
    commit('SET_PROJECTS', projects)
  },

  async getProjectGroups({ commit, state }, id = null) {
    try {
      if (!id) id = state.projects.currentProject.id || '0'
      const { groups } = await ApiProjects.getProjectGroups(id)
      commit('SET_PROJECT_GROUPS', groups)
    } catch (err) {
      commit('SET_PROJECT_ERROR', err.message)
      throw err
    }
  },

  async getProject({ commit }, id) {
    try {
      const {
        // assessmentMappings,
        project,
      } = await ApiProjects.getProject(id)
      commit('SET_PROJECT', project)
      // commit('SET_PROJECT_ASSESSMENT_MAPPINGS', assessmentMappings)
      commit('SET_PROJECT_ERROR', null)
    } catch (err) {
      commit('SET_PROJECT_ERROR', err.message)
      throw err
    }
  },

  async getProjectTasks(
    { commit, state },
    { reset = state.globalReset, projectId = null }
  ) {
    try {
      if (
        state.projects.tasks.length > 0 &&
        state.projects.allTagsInProject.length > 0 &&
        !reset
      )
        return

      const { tasks, tags } = await ApiProjects.getAllTasks(
        projectId ? projectId : state.projects.currentProject.id || '0',
        state.projects.taskFilters,
        state.projects.taskSorting,
        state.projects.tasks.currentPage,
        state.projects.tasks.perPage
      )
      commit('SET_PROJECT_TASKS', tasks)
      commit('SET_PROJECT_TAGS', tags)
      commit('SET_PROJECT_ERROR', null)
    } catch (err) {
      commit('SET_PROJECT_ERROR', err.message)
      throw err
    }
  },

  async getTaskSubtasks({ commit, state }, reset = state.globalReset) {
    try {
      if (state.projects.currentTask.subtasks.length > 0 && !reset) return
      const info = await ApiProjects.getSubtasks(
        state.projects.currentTask.record.id
      )

      commit('SET_PROJECT_TASK_SUBTASKS', info)
    } catch (err) {
      commit('SET_PROJECT_ERROR', err.message)
      throw err
    }
  },

  async clearProjectManagementFilters({ commit }) {
    commit('CLEAR_PROJECT_TASK_FILTERS')
  },

  // async getProjectPolicies({ commit, state } /* , reset = false */) {
  //   // if (state.projects.impactScores.length > 0 && !reset) return
  //   if (!state.projects.currentRegister.id) return

  //   const policies = await ApiProjects.getPolicies(
  //     state.projects.currentRegister.id
  //   )
  //   commit('SET_PROJECT_POLICIES', policies)
  // },

  async getProjectStatuses({ commit, state }, reset = state.globalReset) {
    if (state.projects.statuses.length > 0 && !reset) return

    const { statuses } = await ApiProjects.getStatuses()
    commit('SET_PROJECT_STATUSES', statuses)
  },

  async projectTaskChangePage({ commit, dispatch }, newPage) {
    commit('SET_PROJECT_TASKS_PAGE', newPage)
    await dispatch('getProjectTasks', { reset: true })
  },

  async projectTaskChangePerPage({ commit, dispatch }, newPerPage) {
    commit('SET_PROJECT_TASKS_PAGE', 1)
    commit('SET_PROJECT_TASKS_PER_PAGE', newPerPage)
    await dispatch('getProjectTasks', { reset: true })
  },

  async projectTaskFrontendChangePage({ commit, dispatch }, newPage) {
    await dispatch('getProjectTasks', { reset: false })
    commit('SET_PROJECT_FRONTEND_PAGINATION_OBJ', {
      currentPage: newPage,
    })
  },

  async projectTaskFrontendChangePerPage(
    { commit, state, dispatch },
    { newPerPage, reset = false }
  ) {
    newPerPage = newPerPage ?? state.projects.frontendPaginatedData.perPage
    await dispatch('getProjectTasks', { reset })
    commit('SET_PROJECT_FRONTEND_PAGINATION_OBJ', { perPage: newPerPage })
  },

  async getProjectTask(
    { commit, state },
    taskId = state.projects.currentTask.record.id
  ) {
    try {
      const {
        task,
        comments,
        files,
        owners,
        collaborators,
        taskTags,
        changelog,
        controls,
        formalFindings,
        riskItems,
        penTestFindings,
        myControls,
        evidence,
        uploadUsers,
        scopeGroups,
      } = await ApiProjects.getTask(taskId)

      commit('SET_PROJECT_TASK', task)
      commit('SET_PROJECT_TASK_COMMENTS', comments)
      commit('SET_PROJECT_TASK_FILES', files)
      commit('SET_PROJECT_TASK_OWNERS', owners)
      commit('SET_PROJECT_TASK_COLLABORATORS', collaborators)
      commit('SET_PROJECT_TASK_TAGS', taskTags)
      commit('SET_PROJECT_TASK_CHANGELOG', changelog)
      commit('SET_PROJECT_TASK_CONTROLS', controls)
      commit('SET_PROJECT_TASK_FORMAL_FINDINGS', formalFindings)
      commit('SET_PROJECT_TASK_RISK_ITEMS', riskItems)
      commit('SET_PROJECT_TASK_PENTEST_FINDINGS', penTestFindings)
      commit('SET_PROJECT_TASK_MY_CONTROLS', myControls)
      commit('SET_PROJECT_TASK_EVIDENCE', evidence)
      commit('SET_PROJECT_TASK_UPLOAD_USERS', uploadUsers)
      commit('SET_PROJECT_TASK_SCOPE_GROUPS', scopeGroups)
      commit('SET_PROJECT_ERROR', null)
    } catch (err) {
      commit('SET_PROJECT_ERROR', err.message)
      throw err
    }
  },

  async setProjectFilterAndGetTasks({ commit, dispatch }, { key, val }) {
    commit('SET_PROJECT_TASK_FILTER', { key, val })
    await dispatch('getProjectTasks', { reset: true })
  },

  async setTaskSorting({ commit, dispatch }, { key, val }) {
    commit('SET_PROJECT_TASK_SORTING', { key, val })
    await dispatch('getProjectTasks', { reset: true })
  },

  async projectGroupOrderingUpdate(
    { dispatch },
    { projectId, groupIds, startIndex }
  ) {
    await ApiProjects.updateGroupOrdering(projectId, groupIds, startIndex)
    await dispatch('getProjectTasks', { reset: true })
  },

  async projectTaskOrderingUpdate(
    { dispatch },
    { projectId, taskIds, startIndex }
  ) {
    await ApiProjects.updateTaskOrdering(projectId, taskIds, startIndex)
    await dispatch('getProjectTasks', { reset: true })
  },

  async getAllProjectManagementOwners({ commit }) {
    const owners = await ApiProjects.getAllProjectManagementOwners()
    commit('SET_PROJECT_MANAGEMENT_ALL_OWNERS', owners)
  },

  async getAllProjectManagementGroups({ commit }) {
    const groups = await ApiProjects.getAllProjectManagementGroups()
    commit('SET_PROJECT_MANAGEMENT_ALL_GROUPS', groups)
  },

  async getAllProjectManagementTags({ commit }) {
    const tags = await ApiProjects.getAllProjectManagementTags()
    commit('SET_PROJECT_MANAGEMENT_ALL_TAGS', tags)
  },

  async getAllProjectManagementScopes({ commit }) {
    const scopes = await ApiProjects.getAllProjectManagementScopes()
    commit('SET_PROJECT_MANAGEMENT_ALL_SCOPE_GROUPS', scopes)
  },

  async getProjectManagementDashboardData({ dispatch, state }) {
    const countTypes = state.projects.dashboardCountCardTypes

    await Promise.all([
      ...countTypes.map((countType) =>
        dispatch('getProjectManagementDashboardTaskCountsData', { countType })
      ),
      dispatch('getProjectManagementDashboardOwnerCounts'),
      dispatch('getProjectDashboardByCriticality'),
      dispatch('getProjectDashboardAging'),
      dispatch('getProjectDashboardTasksBehindSchedule'),
      dispatch('getProjectDashboardTaskHistory'),
      dispatch('getProjectsDashboardByXCounts'),
    ])
  },

  async getProjectManagementDashboardTaskCountsData(
    { state, commit },
    { countType }
  ) {
    function toggleLoading(com, key, val) {
      com('SET_PROJECTS_DASHBOARD_LOADING', { key, val })
    }

    function addData(com, key, val) {
      com('SET_PROJECTS_DASHBOARD_DATA', { key, val })
    }

    if (countType == 'total-tasks') {
      toggleLoading(commit, 'tasksTotalCount', true)

      const { count } = await ApiProjects.getDashboardTaskCount(
        state.projects.dashboardFilters
      )
      addData(commit, 'tasksTotalCount', count)

      toggleLoading(commit, 'tasksTotalCount', false)
    } else if (countType == 'tasks-opened') {
      toggleLoading(commit, 'tasksOpenedCount', true)

      const taskOpenedFilters = {
        ...state.projects.dashboardFilters,
        dateRange: state.projects.dashboardFilters.tasksOpenedDateRange,
        limitByDateType: 'open',
      }
      const { count } = await ApiProjects.getDashboardTaskCount(
        taskOpenedFilters
      )
      addData(commit, 'tasksOpenedCount', count)

      toggleLoading(commit, 'tasksOpenedCount', false)
    } else if (countType == 'tasks-marked-complete') {
      toggleLoading(commit, 'tasksMarkedCompleteCount', true)

      const taskCompletedFilters = {
        ...state.projects.dashboardFilters,
        dateRange: state.projects.dashboardFilters.tasksMarkedCompleteDateRange,
        limitByDateType: 'completed',
      }
      const { count } = await ApiProjects.getDashboardTaskCount(
        taskCompletedFilters
      )
      addData(commit, 'tasksMarkedCompleteCount', count)

      toggleLoading(commit, 'tasksMarkedCompleteCount', false)
    }
  },

  async getProjectManagementDashboardOwnerCounts({ state, commit }) {
    commit('SET_PROJECTS_DASHBOARD_LOADING', {
      key: 'tasksByOwner',
      val: true,
    })

    try {
      const counts = await ApiProjects.getDashboardOwnerCounts(
        state.projects.dashboardFilters
      )
      commit('SET_PROJECTS_DASHBOARD_DATA', {
        key: 'ownerCounts',
        val: counts,
      })
    } finally {
      commit('SET_PROJECTS_DASHBOARD_LOADING', {
        key: 'tasksByOwner',
        val: false,
      })
    }
  },

  async getProjectDashboardByCriticality({ state, commit }) {
    commit('SET_PROJECTS_DASHBOARD_LOADING', {
      key: 'taskCountsByCriticality',
      val: true,
    })

    try {
      const { countsByCriticality } =
        await ApiProjects.getDashboardCriticalityCounts(
          state.projects.dashboardFilters
        )

      commit('SET_PROJECTS_DASHBOARD_DATA', {
        key: 'taskCountsByCriticality',
        val: countsByCriticality,
      })
    } finally {
      commit('SET_PROJECTS_DASHBOARD_LOADING', {
        key: 'taskCountsByCriticality',
        val: false,
      })
    }
  },

  async getProjectDashboardAging({ state, commit }) {
    commit('SET_PROJECTS_DASHBOARD_LOADING', {
      key: 'taskAging',
      val: true,
    })

    const { taskAging } = await ApiProjects.getDashboardTaskAging(
      state.projects.dashboardFilters
    )

    commit('SET_PROJECTS_DASHBOARD_DATA', {
      key: 'taskAging',
      val: taskAging,
    })
    commit('SET_PROJECTS_DASHBOARD_LOADING', {
      key: 'taskAging',
      val: false,
    })
  },

  async getProjectDashboardTasksBehindSchedule({ state, commit }) {
    commit('SET_PROJECTS_DASHBOARD_LOADING', {
      key: 'tasksBehindSchedule',
      val: true,
    })

    const tasksBehindSchedule =
      await ApiProjects.getDashboardTasksBehindSchedule(
        state.projects.dashboardFilters
      )

    commit('SET_PROJECTS_DASHBOARD_DATA', {
      key: 'tasksBehindSchedule',
      val: tasksBehindSchedule,
    })
    commit('SET_PROJECTS_DASHBOARD_LOADING', {
      key: 'tasksBehindSchedule',
      val: false,
    })
  },

  async getProjectDashboardTaskHistory({ state, commit }) {
    commit('SET_PROJECTS_DASHBOARD_LOADING', {
      key: 'taskHistory',
      val: true,
    })

    const taskHistory = await ApiProjects.getDashboardTaskHistory(
      state.projects.dashboardFilters
    )

    commit('SET_PROJECTS_DASHBOARD_DATA', {
      key: 'taskHistory',
      val: taskHistory,
    })
    commit('SET_PROJECTS_DASHBOARD_LOADING', {
      key: 'taskHistory',
      val: false,
    })
  },

  setProjectManagementDashboardFilter({ commit }, { key, val }) {
    commit('SET_PROJECTS_DASHBOARD_FILTER', { key, val })
  },

  setProjectManagementDashboardDateRange({ commit }, { countType, dateRange }) {
    switch (countType) {
      case 'tasks-opened':
        commit('SET_PROJECTS_DASHBOARD_FILTER', {
          key: 'tasksOpenedDateRange',
          val: dateRange,
        })
        break
      case 'tasks-marked-complete':
        commit('SET_PROJECTS_DASHBOARD_FILTER', {
          key: 'tasksMarkedCompleteDateRange',
          val: dateRange,
        })
        break
      case 'tasks-history':
        commit('SET_PROJECTS_DASHBOARD_FILTER', {
          key: 'taskHistoryDateRange',
          val: dateRange,
        })
        break
    }
  },

  async getProjectsDashboardByXCounts({ state, commit }) {
    const groupBy = state.projects.dashboardTaskByXGroupBy.value
    const countType = state.projects.dashboardTaskByXCountType
    const dashboardFilters = state.projects.dashboardFilters

    commit('SET_PROJECTS_DASHBOARD_LOADING', {
      key: 'tasksByX',
      val: true,
    })

    try {
      const counts = await ApiProjects.getDashboardByXCounts(
        groupBy,
        countType,
        dashboardFilters
      )
      commit('SET_PROJECTS_DASHBOARD_DATA', {
        key: 'byXCounts',
        val: counts,
      })
    } finally {
      commit('SET_PROJECTS_DASHBOARD_LOADING', {
        key: 'tasksByX',
        val: false,
      })
    }
  },

  setTaskByXGroupBy({ commit }, groupBy) {
    commit('SET_PROJECTS_DASHBOARD_BY_X_GROUP_BY', groupBy)
  },

  setTaskByXCountType({ commit }, countType) {
    commit('SET_PROJECTS_DASHBOARD_BY_X_COUNT_TYPE', countType)
  },

  async getProjectRecentActivity({ commit, state }) {
    const projectId = state.projects.currentProject.id
    if (!projectId) return

    const page = state.projects.recentActivity.page
    const perPage = state.projects.recentActivity.perPage

    const filters = state.projects.recentActivityFilters
    const { info } = await ApiProjects.getRecentActivity(
      projectId,
      filters,
      page,
      perPage
    )
    commit('SET_PROJECTS_RECENT_ACTIVITY', info)
  },

  async setProjectRecentActivityFilter({ commit, dispatch }, { key, val }) {
    commit('SET_PROJECTS_RECENT_ACTIVITY_FILTER', { key, val })
    commit('SET_PROJECTS_RECENT_ACTIVITY_PAGE', 1)
    await dispatch('getProjectRecentActivity', { reset: true })
  },

  async clearProjectRecentActivityFilters({ commit, dispatch }) {
    commit('CLEAR_PROJECTS_RECENT_ACTIVITY_FILTERS')
    commit('SET_PROJECTS_RECENT_ACTIVITY_PAGE', 1)
    await dispatch('getProjectRecentActivity', { reset: true })
  },

  async projectRecentActivityChangePage({ commit, dispatch }, newPage) {
    commit('SET_PROJECTS_RECENT_ACTIVITY_PAGE', newPage)
    await dispatch('getProjectRecentActivity', { reset: true })
  },

  async projectRecentActivityChangePerPage(
    { commit, dispatch, state },
    newPerPage
  ) {
    commit('SET_PROJECTS_RECENT_ACTIVITY_PAGE', 1)
    commit('SET_PROJECTS_RECENT_ACTIVITY_PER_PAGE', newPerPage)
    await dispatch('getProjectRecentActivity', { reset: true })
  },
}
